import { Button, Card, Form, Input, Layout, Row, Col, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImageComponent from './common/ImageComponent';
import '../styles/common.css';
import { LoginFormInput } from '../models/auth';
import { authApi } from '../api/apiClient';
import { useCommitLogin } from '../store/useAuthStore';
import { useFetchMe } from '../store/useMeStore';
import validateMessages from '../utils/validateMessages';

const rowGutterParam = 24;
const labelSpanParam = 8;
const formSpanParam = 18;

const LoginComponent: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const commitLogin = useCommitLogin();
  const fetchMe = useFetchMe();

  const onFinish = async (fieldsValue: LoginFormInput) => {
    try {
      await authApi.authControllerLogin({
        email: fieldsValue.email,
        password: fieldsValue.password,
      });
      commitLogin();
      await fetchMe();
      await message.success(`ログインに成功しました。`);
      navigate('/search');
    } catch (e) {
      await message.error(`ログインに失敗しました。`);
    }
  };

  const { Content } = Layout;
  return (
    <Content className="contentCenter">
      <Row gutter={24}>
        <Card className="cardStyleInDetail">
          <Form
            name="login"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            colon={false}
            validateMessages={validateMessages}>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="contentCenter" style={{ width: '150px', height: '150px' }}>
                <ImageComponent imageUrl="./sengikyo-logo.png" />
              </Col>
            </Row>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} style={{ left: '68px', textAlign: 'left' }}>
                <Content className="formLabel">Email</Content>
              </Col>
              <Col span={formSpanParam} className="contentCenter">
                <Form.Item name="email" rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} style={{ left: '68px', textAlign: 'left' }}>
                <Content className="formLabel">パスワード</Content>
              </Col>
              <Col span={formSpanParam} className="contentCenter">
                <Form.Item name="password" rules={[{ required: true }]}>
                  <Input.Password size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ bottom: '-30px', width: '120px' }}>
                  ログイン
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Card>
      </Row>
    </Content>
  );
};

export default LoginComponent;
