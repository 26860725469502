import { Button, Card, Form, Input, Layout, Row, Col, Modal, FormInstance, message } from 'antd';
import axios, { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { synonymApi } from '../api/apiClient';
import { IErrorResponse } from '../models/other';
import { WordOfDictionary, SynonymFormType } from '../models/synonym';
import Loading from '../pages/Loading';
import '../styles/common.css';
import validateMessages from '../utils/validateMessages';

const rowGutterParam = 24;
const formSpanParam = 10;

const SynonymEdit: React.FC<{
  synonymValues: WordOfDictionary;
  setSynonymValues: React.Dispatch<React.SetStateAction<WordOfDictionary>>;
  form: FormInstance<SynonymFormType>;
  isLoading: boolean;
}> = ({ synonymValues, setSynonymValues, form, isLoading }) => {
  const navigate = useNavigate();
  const [isStoredItemModalVisible, setIsStoredItemModalVisible] = useState(false);
  const [editingSynonymKey, setEditingSynonymKey] = useState<number>(-1);
  // 削除する項目のkeyの情報をモーダルを経由しても保持できるようにするstate(keyは他の類義語が削除されてもfetch時に振られた値から不変)
  const [deleteSynonymKey, setDeleteSynonymKey] = useState<number>(-1);
  // 「編集」ボタン押下時に入力欄に入っていた値
  const [beforeEditWord, setBeforeEditWord] = useState<string>('');

  // 類義語の編集中にほかの「編集」ボタン等（「完了」以外のボタン）を押下した場合、編集前の類義語に戻す
  const revertEditingWord = (): void => {
    if (editingSynonymKey !== -1) {
      const newStoredSynonyms = form.getFieldsValue().storedSynonyms;
      newStoredSynonyms[editingSynonymKey] = beforeEditWord;
      form.setFieldsValue({ storedSynonyms: newStoredSynonyms });
      setEditingSynonymKey(-1);
    }
  };

  // 各単語横の編集ボタン押下時
  const edit = (synonymKey: number) => {
    revertEditingWord();
    const currentWord = form.getFieldsValue().storedSynonyms[synonymKey];
    setBeforeEditWord(currentWord);
    setEditingSynonymKey(synonymKey);
  };

  // 入力値バリデーションチェック
  const isFormValid = async (currentWord: string) => {
    if (!currentWord) {
      await message.error('類義語を入力をしてください。');
      return false;
    }
    if (currentWord.indexOf(',') !== -1) {
      // 半角カンマが含まれてる
      await message.error('半角コンマを含む文字は登録できません。');
      return false;
    }
    return true;
  };

  // 各単語横の完了ボタン押下時
  const editComplete = async (synonymKey: number) => {
    const currentWord = form.getFieldsValue().storedSynonyms[synonymKey];
    // onFinishを使ったバリデーション実装ができなかったため、エラーメッセージは以下のように出している（できなかった理由：「完了」ボタンにsubmit属性を付与すると同じ位置にある「編集」ボタンを押下した際にもsubmitされてしまうようである）
    if (!(await isFormValid(currentWord))) {
      return;
    }
    const updateSynonym = { current_word: String(currentWord), prev_word: beforeEditWord };
    try {
      await synonymApi.synonymControllerUpdateSynonym(synonymValues.group_id, updateSynonym);
      setEditingSynonymKey(-1);
      await message.success(`編集が完了し、${currentWord}が追加されました。`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response?.status === 404) {
          if ((e as AxiosError<IErrorResponse>).response?.data.message === 'word already exists') {
            await message.error('既に存在する類義語を登録することはできません');
          }
        }
      } else {
        await message.error('類義語編集に失敗しました。');
      }
    }
  };

  // 新規追加類義語の登録ボタン押下時
  const addSynonym = async () => {
    const currentWord = form.getFieldsValue().newSynonym;
    if (!(await isFormValid(currentWord))) {
      return;
    }
    const registerSynonym = { current_word: currentWord, prev_word: '' };
    try {
      await synonymApi.synonymControllerUpdateSynonym(synonymValues.group_id, registerSynonym);
      // 新規追加した類義語をstoredSynonymsに加える
      const newSynonyms = {
        company_id: synonymValues.company_id,
        group_id: synonymValues.group_id,
        words: [...form.getFieldsValue().storedSynonyms, currentWord],
      };
      setSynonymValues(newSynonyms);
      await message.success(`${currentWord}が追加されました。`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response?.status === 404) {
          if ((e as AxiosError<IErrorResponse>).response?.data.message === 'word already exists') {
            await message.error('既に存在する類義語を登録することはできません');
          }
        }
      } else {
        await message.error('類義語登録に失敗しました。');
      }
    }
  };

  // 各単語横の削除ボタン押下時
  const showModal = (fieldKey: number) => {
    revertEditingWord();
    setDeleteSynonymKey(fieldKey);
    setIsStoredItemModalVisible(true);
  };
  // 削除モーダルから削除実行時
  const modalDeleteOk = async (synonymKey: number) => {
    setIsStoredItemModalVisible(false);
    try {
      const deleteSynonym = { word: synonymValues.words[synonymKey] };
      await synonymApi.synonymControllerDeleteSynonym(synonymValues.group_id, deleteSynonym);
      // 削除した類義語をsynonymValuesから消す
      const newSynonymWord = [...synonymValues.words];
      newSynonymWord.splice(synonymKey, 1);
      const newSynonyms = {
        company_id: synonymValues.company_id,
        group_id: synonymValues.group_id,
        words: newSynonymWord,
      };
      setSynonymValues(newSynonyms);
      await message.success(`${deleteSynonym.word}が削除されました。`);
    } catch (e) {
      await message.error('類義語削除に失敗しました。');
    }
  };
  // 削除モーダルからキャンセル実行時
  const modalCancel = () => {
    setIsStoredItemModalVisible(false);
  };
  const backToSynonymHome = () => {
    navigate('/synonym');
  };

  const FormRef = useRef<FormInstance>(null);

  const { Content } = Layout;
  return (
    <Content className="contentCenter">
      <Form
        name="synonymEdit"
        form={form}
        ref={FormRef}
        autoComplete="off"
        colon={false}
        initialValues={synonymValues}
        validateMessages={validateMessages}>
        <Row gutter={24}>
          <Card className="cardStyleInDetail" style={{ overflow: 'auto' }}>
            {(() => {
              if (isLoading) {
                return (
                  <Col span={8}>
                    <Loading />
                  </Col>
                );
              }
              if (!synonymValues.words.length) {
                return null;
              }
              return (
                <div>
                  <Form.List name="storedSynonyms" initialValue={synonymValues.words}>
                    {(fields): JSX.Element => (
                      <>
                        {fields.map((field) => (
                          <Row gutter={rowGutterParam} className="rowStyle" key={field.key}>
                            <Col span={formSpanParam}>
                              <Form.Item
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                                // 登録ボタンがhtmlType="submit"ではないので以下のrulesはバリデーションとしてのはじく機能はなく、入力欄を赤くする役割として使用
                                rules={[
                                  { required: true, message: '編集後の類義語を入力してください。' },
                                  {
                                    pattern: /^(?!.*,).*$/,
                                    message: '半角コンマを含む文字は登録できません。',
                                  },
                                ]}>
                                <Input disabled={editingSynonymKey !== field.key} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item>
                                {editingSynonymKey !== field.key ? (
                                  <Button type="primary" htmlType="button" onClick={() => edit(field.key)}>
                                    編集
                                  </Button>
                                ) : (
                                  <Button type="primary" htmlType="button" onClick={() => editComplete(field.key)}>
                                    完了
                                  </Button>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  danger
                                  htmlType="button"
                                  onClick={() => {
                                    showModal(field.key);
                                  }}>
                                  削除
                                </Button>
                                <Modal
                                  visible={isStoredItemModalVisible}
                                  onCancel={modalCancel}
                                  mask={false}
                                  footer={[
                                    <Button
                                      key="delete"
                                      type="primary"
                                      danger
                                      onClick={async () => {
                                        await modalDeleteOk(deleteSynonymKey);
                                      }}>
                                      はい
                                    </Button>,
                                    <Button key="cancel" onClick={modalCancel}>
                                      いいえ
                                    </Button>,
                                  ]}>
                                  <p>本当に削除しますか？</p>
                                </Modal>
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                  <Row gutter={rowGutterParam} className="rowStyle">
                    <Col span={formSpanParam}>
                      <Form.Item
                        className="contentCenter"
                        name="newSynonym"
                        // 登録ボタンがhtmlType="submit"ではないので以下のrulesはバリデーションとしてのはじく機能はなく、入力欄を赤くする役割として使用
                        rules={[
                          { required: true, message: '新規類義語を入力してください。' },
                          {
                            pattern: /^(?!.*,).*$/,
                            message: '半角コンマを含む文字は登録できません。',
                          },
                        ]}>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Button type="primary" htmlType="button" onClick={() => addSynonym()}>
                        登録
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })()}
          </Card>
        </Row>
        <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Form.Item>
            <Button htmlType="button" onClick={backToSynonymHome}>
              戻る
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Content>
  );
};

export default SynonymEdit;
