import { Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { PdfGroup, SearchResponse } from '../generated-api';
import { SearchRequest, SearchTarget } from '../models/search';
import { useMeStore } from '../store/useMeStore';
import { useSearchRequest, useSearchResult, useSearchResultBeforeFilter } from '../store/useSearchStore';
import PdfDownloadButton from './common/button/PdfDownloadButton';

interface SearchResultProps {
  searchTarget: SearchTarget;
  foundPdf: PdfGroup;
  searchRequest: SearchRequest;
  searchResponseBeforeFilter: SearchResponse;
  searchResponse: SearchResponse;
}

const MAX_LIST_ITEMS = 3;

const SearchResult: React.FC<SearchResultProps> = (props) => {
  const { foundPdf, searchTarget, searchRequest, searchResponseBeforeFilter, searchResponse } = props;
  const { setSavedSearchRequest } = useSearchRequest();
  const { setSavedSearchResult } = useSearchResult();
  const { setSavedSearchResultBeforeFilter } = useSearchResultBeforeFilter();
  const me = useMeStore();

  const onClick = () => {
    // 検索条件と検索結果をrecoilに保持
    setSavedSearchRequest(searchRequest);
    setSavedSearchResult(searchResponse);
    setSavedSearchResultBeforeFilter(searchResponseBeforeFilter);
  };

  // foundPdf.contentとして渡ってきた配列のうち、スコア順MAX_LIST_ITEMS個絞り込む
  const itemTopN = foundPdf.content.slice(0, Math.min(foundPdf.content.length, MAX_LIST_ITEMS));
  const pdfTitle = foundPdf.file_name || 'タイトル不明';

  const itemsToShow = itemTopN.map((item, index) => {
    const replaced = reactStringReplace(foundPdf.content[index].text, /<em>(.*?)<\/em>/g, (match, i) => (
      <span className="textHighlight" key={i}>
        {match}
      </span>
    ));
    return { ...item, replaced };
  });
  return (
    <List
      header={
        <Row justify="space-between">
          <Col>
            <Link onClick={onClick} to={`/pdf/${searchTarget}/${foundPdf.pdf_id}`} className="searchResultPdfTitle">
              {pdfTitle}
            </Link>
          </Col>
          {(me.company_id === foundPdf.company_id || foundPdf.company_id === 'sengikyo') && (
            <Col pull={1}>
              <PdfDownloadButton fileName={foundPdf.file_name} filePath={foundPdf.file_path} />
            </Col>
          )}
        </Row>
      }
      dataSource={itemsToShow}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <Row className="searchResultEachPage">
            <Col>
              <Link
                onClick={onClick}
                to={`/pdf/${searchTarget}/${foundPdf.pdf_id}?page=${Number(item.page_number) + 1}`}>
                {`p.${Number(item.page_number) + 1}`}
              </Link>
            </Col>
            <Col>{item.replaced}</Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default SearchResult;
