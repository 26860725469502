import { Button, Col, Form, Row, Image, Typography, Alert } from 'antd';
import React, { useEffect } from 'react';
import { PDFPageProxy } from 'pdfjs-dist';
import { pdfRender } from '../../utils/pdfUtils';
import Loading from '../../pages/Loading';

const PdfPreview: React.FC<{
  result: string;
  totalPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  setResult: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  pages: PDFPageProxy[];
  aspect: number;
  setAspect: React.Dispatch<React.SetStateAction<number>>;
}> = (props) => {
  const { result, totalPage, currentPage, setCurrentPage, setResult, isLoading, pages, aspect, setAspect } = props;
  const rowGutterParam = 24;
  let height = 580;
  let width = 580;
  if (aspect > 1) {
    width /= aspect;
  } else {
    height *= aspect;
  }
  useEffect(() => {
    const f = async () => {
      await pdfRender(pages, currentPage, setResult, setAspect);
    };
    f().catch(console.error);
  }, [pages, currentPage, setResult, setAspect]);

  const backPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <Row gutter={rowGutterParam} align="middle">
        <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '580px' }}>
          {(() => {
            if (isLoading) {
              return <Loading />;
            }
            if (!result) {
              return <Alert message="PDFが取得できませんでした" type="warning" showIcon />;
            }
            return (
              <Form.Item name="pdf_url">
                <Image src={result} alt="PDF画像" height={height} width={width} />
              </Form.Item>
            );
          })()}
        </Col>
      </Row>
      <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button onClick={() => backPage()}>前ページ</Button>
        <Typography.Title level={5}>
          {currentPage}/{totalPage}ページ
        </Typography.Title>
        <Button onClick={() => nextPage()}>次ページ</Button>
      </Row>
    </>
  );
};

export default PdfPreview;
