import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { PdfResponse } from '../generated-api';

export const ISOStringToHumanReadable = (isostr: string) => {
  const d = new Date(isostr);
  return format(d, 'yyyy-MM-dd HH:mm', { locale: ja });
};

export const dateTimeFormatPdfUpdateAt = (pdfs: PdfResponse[]) => {
  const pdfArray = [...pdfs].map((pdf) => {
    if (pdf.updated_at) {
      return { ...pdf, updated_at: ISOStringToHumanReadable(pdf.updated_at) };
    }
    return { ...pdf };
  });
  return pdfArray;
};
