import { Form, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderComponent from '../components/common/HeaderComponent';
import UserEditComponent from '../components/UserEdit';
import { userApi } from '../api/apiClient';
import { UserResponse } from '../generated-api';
import { ISOStringToHumanReadable } from '../utils/dateUtils';

const UserEdit: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserResponse>({
    company_id: '',
    created_at: '',
    deleted_at: '',
    email: '',
    last_login_at: '',
    name: '',
    updated_at: '',
    user_id: '',
  });

  const { id } = useParams();
  const [form] = Form.useForm();

  // ユーザー詳細を取得する処理
  useEffect(() => {
    const fetchUserDetail: () => Promise<void> = async () => {
      if (id !== undefined) {
        const user = await userApi.userControllerGetUserById(id);
        if (user.data.last_login_at) {
          user.data.last_login_at = ISOStringToHumanReadable(user.data.last_login_at);
        }
        setUserInfo(user.data);
      }
    };
    fetchUserDetail().catch(console.error);
  }, [id]);

  useEffect(() => form.resetFields(), [userInfo, form]);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <UserEditComponent initialValues={userInfo} form={form} />
    </Layout>
  );
};

export default UserEdit;
