import { Layout } from 'antd';
import { PDFPageProxy } from 'pdfjs-dist';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { companyApi, pdfApi } from '../api/apiClient';
import HeaderComponent from '../components/common/HeaderComponent';
import SearchDetailComponent from '../components/SearchDetail';
import { useMeStore } from '../store/useMeStore';
import { pdfLoad } from '../utils/pdfUtils';

const SearchDetail: React.FC = () => {
  const { target, pdfId } = useParams();
  const me = useMeStore();
  const [pages, setPages] = useState<PDFPageProxy[]>([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');
  const [aspect, setAspect] = useState<number>(1);
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const initialPage = query.get('page') ? Number(query.get('page')) : 1;
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    const getPdfs = async () => {
      if (pdfId !== undefined) {
        let companyId = '';
        if (target === 'internal') {
          companyId = me.company_id;
        } else if (target === 'sengikyo') {
          companyId = (await companyApi.companyControllerGetSengikyoId()).data.sengikyo_company_id;
        } else {
          navigate('/search');
        }
        const filePath = `${companyId}/${pdfId}.pdf`;
        const res = await pdfApi.pdfControllerGetPresignedUrl({
          file_path: filePath,
          operation: 'getObject',
        });
        await pdfLoad(res.data.presigned_url, setTotalPage, setPages, setResult, setAspect, initialPage);
      }
    };
    getPdfs()
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, [pdfId, me.company_id, navigate, initialPage, target]);
  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <SearchDetailComponent
        totalPage={totalPage}
        pages={pages}
        isLoading={isLoading}
        result={result}
        setResult={setResult}
        aspect={aspect}
        setAspect={setAspect}
        initialPage={initialPage}
      />
    </Layout>
  );
};

export default SearchDetail;
