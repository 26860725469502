import './App.css';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import Login from './pages/Login';
import Search from './pages/Search';
import SearchDetail from './pages/SearchDetail';
import SearchLog from './pages/SearchLog';
import UserAdd from './pages/UserAdd';
import UserEdit from './pages/UserEdit';
import UserList from './pages/UserList';
import SynonymList from './pages/SynonymList';
import SynonymEdit from './pages/SynonymEdit';
import SynonymAdd from './pages/SynonymAdd';
import PdfManagement from './pages/PdfManagement';
import { isAuthorizedState, useAuthStore, useInitAuth } from './store/useAuthStore';
import Loading from './pages/Loading';
import PdfAttribute from './pages/PdfAttribute';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isAuthorized = useRecoilValue(isAuthorizedState);
  const location = useLocation();

  if (!isAuthorized) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const App: React.FC = () => {
  const [isAuthInitiated] = useAuthStore();

  const initAuth = useInitAuth();

  useEffect(() => {
    const init = async () => {
      await initAuth();
    };
    init().catch(console.error);
  }, [initAuth]);

  return !isAuthInitiated ? (
    <Loading />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Search />
            </RequireAuth>
          }
        />
        <Route
          path="/search"
          element={
            <RequireAuth>
              <Search />
            </RequireAuth>
          }
        />
        <Route
          path="/search/log"
          element={
            <RequireAuth>
              <SearchLog />
            </RequireAuth>
          }
        />
        <Route
          path="/user/new"
          element={
            <RequireAuth>
              <UserAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/user/:id"
          element={
            <RequireAuth>
              <UserEdit />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserList />
            </RequireAuth>
          }
        />
        <Route
          path="/synonym/new"
          element={
            <RequireAuth>
              <SynonymAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/synonym/:id"
          element={
            <RequireAuth>
              <SynonymEdit />
            </RequireAuth>
          }
        />
        <Route
          path="/synonym"
          element={
            <RequireAuth>
              <SynonymList />
            </RequireAuth>
          }
        />
        <Route
          path="/pdf/:target/:pdfId"
          element={
            <RequireAuth>
              <SearchDetail />
            </RequireAuth>
          }
        />
        <Route
          path="/pdf/attribute"
          element={
            <RequireAuth>
              <PdfAttribute />
            </RequireAuth>
          }
        />
        <Route
          path="/pdf"
          element={
            <RequireAuth>
              <PdfManagement />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
