import { Button } from 'antd';
import React from 'react';
import { PdfResponse } from '../../../generated-api';

const PdfTopEditButton: React.FC<{ record: PdfResponse; editPdf: (record: PdfResponse) => void }> = (props) => {
  const { record, editPdf } = props;
  return (
    <span>
      {record?.status === 'DONE' && (
        <Button type="primary" onClick={() => editPdf(record)}>
          編集
        </Button>
      )}
    </span>
  );
};

export default PdfTopEditButton;
