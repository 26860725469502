import { Button, Col, Layout, Form, Row, Pagination, Typography, Card, Alert, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Moment } from 'moment';
import HeaderComponent from '../components/common/HeaderComponent';
import { SEARCH_TARGET_COMPANY, SearchTarget, SearchRequest, SortType } from '../models/search';
import { AttributeModel, PdfGroup, SearchResponse } from '../generated-api';
import { attributeApi, searchApi } from '../api/apiClient';
import SearchResult from '../components/SearchResult';
import { filterByAttributes, filterByDateRange } from '../utils/searchFilter';
import SearchForm from '../components/SearchForm';
import Loading from './Loading';
import { useSearchRequest, useSearchResult, useSearchResultBeforeFilter } from '../store/useSearchStore';
import sortSearchResponse from '../utils/sortSearchResponse';

interface PaginationSetting {
  currentPage: number;
  pageSize: number;
}

const Search: React.FC = () => {
  const [form] = Form.useForm();
  const initialValues: SearchRequest = {
    searchKeyword: '',
    searchTarget: SEARCH_TARGET_COMPANY as SearchTarget,
    searchAttributes: [],
    searchDateRange: null,
    searchIsExactMatch: false,
  };

  const [searchRequest, setSearchRequest] = useState<SearchRequest | null>(null);
  const [searchResponse, setSearchResponse] = useState<SearchResponse | null>(null);
  const [searchResponseBeforeFilter, setSearchResponseBeforeFilter] = useState<SearchResponse | null>(null);
  const [attributeList, setAttributeList] = useState<AttributeModel[]>([]);
  const [sortType, setSortType] = useState<SortType>('SCORE');
  const [, setIsExactMatch] = useState<boolean>(true);
  const [relatedWords, setRelatedWords] = useState<string[]>([]);
  const [paginationSetting, setPaginationSetting] = useState<PaginationSetting>({
    currentPage: 1,
    pageSize: 10,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const { savedSearchRequest } = useSearchRequest();
  const { savedSearchResult } = useSearchResult();
  const { savedSearchResultBeforeFilter } = useSearchResultBeforeFilter();

  const location = useLocation();
  type NavigateState = { holdForm: boolean };
  const navigateState = location.state as NavigateState;

  useEffect(() => {
    const init = async () => {
      // attributes
      const fetchAttributeList = await attributeApi.attributeControllerGetAttributes();
      const activeAttributeList = fetchAttributeList.data.filter((attribute) => !attribute.deleted_at);
      const sorted = activeAttributeList.sort((a, b) => a.word.localeCompare(b.word));
      setAttributeList(sorted);

      if (savedSearchRequest && navigateState && navigateState.holdForm) {
        // recoilから取得できるattributeの配列にはidが入っているのでwordに変える（表示時にセレクトボックスの中でidがちらつくのを防ぐ役割）
        const attributeWords: Array<string> = [];
        savedSearchRequest.searchAttributes.forEach((attribute) => {
          const matchAttribute = activeAttributeList.filter((a) => a.id === attribute);
          attributeWords.push(matchAttribute[0].word);
        });
        setSearchResponseBeforeFilter(savedSearchResultBeforeFilter);
        setSearchResponse(savedSearchResult);
        setSearchRequest(savedSearchRequest);
        form.setFieldsValue({
          searchKeyword: savedSearchRequest.searchKeyword,
          searchTarget: savedSearchRequest.searchTarget,
          searchAttributes: attributeWords,
          searchDateRange: savedSearchRequest.searchDateRange,
          searchIsExactMatch: savedSearchRequest.searchIsExactMatch,
        });
      } else {
        // initialValuesを埋める
        const queryParam = searchParams.get('q');
        const queryTarget = searchParams.get('target');
        form.setFieldsValue({ searchKeyword: queryParam || '', searchTarget: queryTarget || 'internal' });
      }
    };
    init().catch(console.error);
  }, [form, savedSearchRequest, savedSearchResult, savedSearchResultBeforeFilter, navigateState, searchParams]);

  const onSearch = async (param: SearchRequest) => {
    setIsLoading(true);
    const searchResult = await searchApi.searchControllerSearch(
      param.searchTarget,
      param.searchKeyword,
      param.searchIsExactMatch,
      param.searchAttributes
    );
    setSearchResponseBeforeFilter(searchResult.data);
    const filteredByAttributes = filterByAttributes(searchResult.data, param.searchAttributes);
    const filteredByDateRange = filterByDateRange(filteredByAttributes, param.searchDateRange);
    setSearchRequest(param);
    setSearchResponse(filteredByDateRange);

    // 関連する検索
    setRelatedWords(searchResult.data.related_queries);

    // urlのquery stringに値をセット
    setSearchParams({
      q: param.searchKeyword,
      target: param.searchTarget,
      is_exact_match: param.searchIsExactMatch as unknown as string,
    });
    setIsLoading(false);
  };

  const onChangePagination = (page: number, pageSize: number) => {
    setPaginationSetting({ currentPage: page, pageSize });
    window.scrollTo({
      top: 0,
    });
  };
  const onRelatedWordClick = (word: string) => {
    if (searchRequest && searchRequest.searchKeyword) {
      setSearchParams({ q: `${searchRequest.searchKeyword} ${word}`, target: `${searchRequest.searchTarget}` });
      setSearchRequest(null);
      setSearchResponse(null);
      setSearchResponseBeforeFilter(null);
      setPaginationSetting({ currentPage: 1, pageSize: 10 });
    }
  };

  const onAttributesChange = (attributeIds: string[]) => {
    if (searchRequest) {
      setSearchRequest({ ...searchRequest, searchAttributes: attributeIds });
    } else {
      setSearchRequest({
        ...initialValues,
        searchAttributes: attributeIds,
      });
    }
    if (!searchResponseBeforeFilter) {
      return;
    }
    const filteredByAttributes = filterByAttributes(searchResponseBeforeFilter, attributeIds);
    const filteredByDateRange =
      searchRequest && searchRequest.searchDateRange
        ? filterByDateRange(filteredByAttributes, searchRequest.searchDateRange)
        : filteredByAttributes;
    setSearchResponse(filteredByDateRange);
  };

  const onDateRangePick = (dates: [Moment | null, Moment | null] | null, _datestring: [string, string]) => {
    let attributes: string[];
    if (searchRequest) {
      if (dates != null) {
        setSearchRequest({ ...searchRequest, searchDateRange: dates });
      }
      setSearchRequest({ ...searchRequest, searchDateRange: dates });
      attributes = searchRequest.searchAttributes;
    } else {
      setSearchRequest({ ...initialValues });
      attributes = [];
    }
    if (!searchResponseBeforeFilter) {
      return;
    }
    const filteredByAttributes = filterByAttributes(searchResponseBeforeFilter, attributes);
    const filteredByDateRange = filterByDateRange(filteredByAttributes, dates);
    setSearchResponse(filteredByDateRange);
  };

  const onSortTypeChange = (value: string) => {
    setSortType(value as SortType);
  };

  const onIsExactMatchChange = ({ target: { value } }: RadioChangeEvent) => {
    setIsExactMatch(value as boolean);
  };

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <div style={{ width: '80%', margin: 'auto' }}>
        {/* 検索フォーム */}
        <SearchForm
          initialValues={{ ...initialValues, sort: 'SCORE' }}
          onSearch={onSearch}
          form={form}
          onAttributesChange={onAttributesChange}
          attributeList={attributeList}
          onDateRangePick={onDateRangePick}
          onSortTypeChange={(e) => onSortTypeChange(e)}
          onIsExactMatchChange={(e) => onIsExactMatchChange(e)}
        />
        {/* 検索結果表示欄 */}
        {(() => {
          if (isLoading) {
            return <Loading />;
          }
          if (searchResponse?.hit_count === 0 && searchRequest) {
            return (
              <Alert
                message={`${searchRequest.searchKeyword}に関する検索条件に一致する情報はありませんでした。`}
                type="warning"
                showIcon
              />
            );
          }
          return (
            <Row>
              <Col span={18}>
                {searchResponse &&
                  searchResponse.hit_count !== 0 &&
                  searchResponseBeforeFilter &&
                  searchRequest &&
                  sortSearchResponse(searchResponse, sortType).result.map(
                    (foundPdf: PdfGroup, index: number) =>
                      index < paginationSetting.currentPage * paginationSetting.pageSize &&
                      (paginationSetting.currentPage - 1) * paginationSetting.pageSize <= index && (
                        <SearchResult
                          key={foundPdf.pdf_id}
                          foundPdf={foundPdf}
                          searchTarget={searchRequest.searchTarget}
                          searchRequest={searchRequest}
                          searchResponseBeforeFilter={searchResponseBeforeFilter}
                          searchResponse={searchResponse}
                        />
                      )
                  )}
                <div style={{ textAlign: 'center' }}>
                  <Pagination
                    defaultCurrent={1}
                    defaultPageSize={10}
                    total={searchResponse ? searchResponse.hit_count : 1}
                    onChange={onChangePagination}
                  />
                </div>
              </Col>
              {/* 関連するワード */}
              <Col span={6}>
                {relatedWords.length !== 0 && (
                  <Card title="関連するワード">
                    {relatedWords.map((word) => (
                      <Typography key={word}>
                        <Button type="link" key={word} onClick={() => onRelatedWordClick(word)}>
                          {word}
                        </Button>
                      </Typography>
                    ))}
                  </Card>
                )}
              </Col>
            </Row>
          );
        })()}
      </div>
    </Layout>
  );
};
export default Search;
