import { Button, Modal } from 'antd';
import React from 'react';
import { PdfResponse } from '../../../generated-api';

const PdfTopDeleteButton: React.FC<{
  record: PdfResponse;
  isModalVisible: boolean;
  deletePdfName: string;
  showModal: (id: string) => void;
  modalDeleteOk: (id: string) => void;
  modalCancel: () => void;
}> = (props) => {
  const { record, isModalVisible, deletePdfName, showModal, modalDeleteOk, modalCancel } = props;
  return (
    <span>
      {(record?.status === 'DONE' || record?.status === 'PROCESSING') && (
        <>
          <Button type="primary" danger onClick={() => showModal(record?.id)}>
            削除
          </Button>
          <Modal
            visible={isModalVisible}
            onOk={() => modalDeleteOk(deletePdfName)}
            onCancel={modalCancel}
            mask={false}
            footer={[
              <Button
                key="delete"
                type="primary"
                danger
                onClick={() => {
                  modalDeleteOk(deletePdfName);
                }}>
                はい
              </Button>,
              <Button key="cancel" onClick={modalCancel}>
                いいえ
              </Button>,
            ]}>
            <p>本当に削除しますか？</p>
          </Modal>
        </>
      )}
    </span>
  );
};

export default PdfTopDeleteButton;
