import React from 'react';
import { Image } from 'antd';

type ImageProps = {
  imageUrl: string;
};

const ImageComponent: React.FC<ImageProps> = ({ imageUrl }) => <Image alt="image" preview={false} src={imageUrl} />;

export default ImageComponent;
