import { Button, Col, Form, Row, Image, InputNumber, FormInstance, Typography, Alert, Divider } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../pages/Loading';

const PdfReading: React.FC<{
  result: string;
  totalPage: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  form: FormInstance;
  isLoading: boolean;
  aspect: number;
}> = (props) => {
  const { result, totalPage, currentPage, setCurrentPage, form, isLoading, aspect } = props;
  const rowGutterParam = 24;
  const { Title } = Typography;
  const navigate = useNavigate();
  let height = 1000;
  let width = 1000;
  if (aspect > 1) {
    width /= aspect;
  } else {
    height *= aspect;
  }

  const backPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const nextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageChange = (value: number) => {
    if (value <= 0 || totalPage < value) {
      form.setFieldsValue({
        page: currentPage,
      });
    } else {
      setCurrentPage(value);
    }
  };
  const backToSearch = () => {
    navigate('/search', { state: { holdForm: true } });
  };

  return (
    <>
      <Row gutter={rowGutterParam} className="rowStyle">
        <Col span={24} className="pdfReadingAlignment">
          <Button type="primary" onClick={backToSearch} style={{ width: '125px' }}>
            戻る
          </Button>
          <Button onClick={backPage} style={{ width: '100px' }}>
            前ページ
          </Button>
          <Title level={4}>
            {currentPage}/{totalPage}ページ
          </Title>
          <Button onClick={nextPage} style={{ width: '100px' }}>
            次ページ
          </Button>
          <InputNumber placeholder="ページ数指定欄" onChange={pageChange} style={{ width: '125px' }} />
        </Col>
      </Row>
      <Divider style={{ margin: '0 0 12px 0' }} />
      {(() => {
        if (isLoading) {
          return <Loading />;
        }
        if (!result) {
          return <Alert message="PDFが取得できませんでした" type="warning" showIcon />;
        }
        return (
          <Row gutter={rowGutterParam} className="rowStyle">
            <Col span={24} style={{ height: '150px', marginBottom: '430px' }}>
              <Form.Item name="pdf_url">
                <Image src={result} alt="PDFがセットされていません" height={height} width={width} />
              </Form.Item>
            </Col>
          </Row>
        );
      })()}
    </>
  );
};

export default PdfReading;
