import { Card, Layout, Row, Col, Typography, Space } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WordOfDictionary } from '../models/synonym';
import Loading from '../pages/Loading';
import '../styles/common.css';
import '../styles/synonym.css';

const { Title, Text } = Typography;

const SynonymListComponent: React.FC<{
  synonymsList: WordOfDictionary[];
  isLoading: boolean;
}> = ({ synonymsList, isLoading }) => {
  const NumberOfDisplayable = 8;
  const { Content } = Layout;
  const navigate = useNavigate();
  const onUserAddClick = () => {
    navigate('/synonym/new');
  };
  const onSynonymClick = (groupId: string) => {
    navigate(`/synonym/${groupId}`);
  };
  return (
    <Content style={{ margin: '24px 48px' }}>
      <Row gutter={24}>
        <Card
          className="cardStyleInList"
          headStyle={{ borderStyle: 'none' }}
          style={{ fontSize: '15px', fontWeight: 500 }}
          title="新規追加"
          onClick={onUserAddClick}
          bordered={false}>
          <br />
          <br />
          <Title className="textAlignCenter">＋</Title>
        </Card>
        {(() => {
          if (isLoading) {
            return (
              <Col span={8}>
                <Loading />
              </Col>
            );
          }
          if (!synonymsList.length) {
            return null;
          }
          return (
            <>
              {synonymsList.map((synonymsData: WordOfDictionary) => (
                <Card
                  key={synonymsData.group_id}
                  style={{ fontSize: '15px', fontWeight: 500 }}
                  headStyle={{ borderStyle: 'none' }}
                  bodyStyle={{ padding: '0px 24px' }}
                  className="cardStyleInList"
                  title={synonymsData.words[0]}
                  onClick={() => {
                    onSynonymClick(synonymsData.group_id);
                  }}
                  bordered={false}>
                  <Space direction="vertical">
                    {synonymsData.words
                      // 配列一番目の項目はタイトルとして出しているので除外し、指定した個数を一覧に表示
                      .slice(0, NumberOfDisplayable)
                      .filter((s: string, index: number) => index !== 0)
                      .map((text: string) => (
                        <Text key={text}>{text}</Text>
                      ))}
                    {synonymsData.words.length > NumberOfDisplayable && <Text>...</Text>}
                  </Space>
                </Card>
              ))}
            </>
          );
        })()}
      </Row>
    </Content>
  );
};

export default SynonymListComponent;
