import { Button, Card, Form, Input, Layout, Row, Col, Modal, FormInstance, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { userApi } from '../api/apiClient';
import { UserEditForm } from '../models/userInfo';
import validateMessages from '../utils/validateMessages';
import { IErrorResponse } from '../models/other';
import '../styles/common.css';
import '../styles/button.css';
import Loading from '../pages/Loading';
import { UpdateUserRequest, UserResponse } from '../generated-api';

const rowGutterParam = 24;
const labelSpanParam = 8;
const formSpanParam = 14;

const UserEditComponent: React.FC<{ initialValues: UserResponse; form: FormInstance }> = ({ initialValues, form }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };
  // 編集ボタン押下時
  const onFinish = async (fieldsValue: UserEditForm) => {
    if (
      (fieldsValue.name === '' || fieldsValue.name === initialValues.name) &&
      (typeof fieldsValue.password === 'undefined' || fieldsValue.password === '') &&
      (typeof fieldsValue.password_confirm === 'undefined' || fieldsValue.password === '')
    ) {
      await message.error('いずれかの項目を入力または編集してください。');
    } else {
      try {
        const updateRequest: UpdateUserRequest = { name: fieldsValue.name, password: fieldsValue.password };
        await userApi.userControllerUpdateUser(initialValues.user_id, updateRequest);
        if (fieldsValue.password && fieldsValue.name !== initialValues.name) {
          await message.success(
            <>
              ユーザー名を{fieldsValue.name}に変更しました。
              <br />
              パスワードを変更しました。
            </>
          );
        } else if (!fieldsValue.password && fieldsValue.name !== initialValues.name) {
          await message.success(`ユーザー名を${fieldsValue.name}に変更しました。`);
        } else if (fieldsValue.password && fieldsValue.name === initialValues.name) {
          await message.success('パスワードを変更しました。');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response && e.response?.status === 404) {
            if ((e as AxiosError<IErrorResponse>).response?.data.message === 'target resource is not yours.') {
              await message.error('他ユーザーの情報を変更することはできません。');
            }
          }
        } else {
          await message.error('ユーザー編集に失敗しました。');
        }
      }
      navigate('/user');
    }
  };

  // 削除モーダルから削除実行時
  const modalDeleteOk = async () => {
    try {
      setIsModalVisible(false);
      await userApi.userControllerDeleteUser(initialValues.user_id);
      await message.success(`ユーザー名:${initialValues.name}を削除しました。`);
    } catch (e) {
      await message.error(`ユーザー名:${initialValues.name}の削除に失敗しました。`);
    }
    navigate('/user');
  };

  const modalCancel = () => {
    setIsModalVisible(false);
  };
  const toUserHome = () => {
    navigate('/user');
  };

  const { Content } = Layout;
  return (
    <Content className="contentCenter">
      <Row gutter={24}>
        {initialValues.name || initialValues.email ? (
          <Form
            name="userEdit"
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            autoComplete="off"
            colon={false}
            validateMessages={validateMessages}>
            <Card className="userCardStyle">
              <Row gutter={rowGutterParam} className="rowStyle">
                <Col span={labelSpanParam} className="colStyle">
                  <Content className="formLabel">ユーザ名</Content>
                </Col>
                <Col span={formSpanParam}>
                  <Form.Item name="name" rules={[{ required: true }]}>
                    <Input size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={rowGutterParam} className="rowStyle">
                <Col span={labelSpanParam} className="colStyle">
                  <Content className="formLabel">Email</Content>
                </Col>
                <Col span={formSpanParam}>
                  <Form.Item name="email">
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={rowGutterParam} className="rowStyle">
                <Col span={labelSpanParam} className="colStyle">
                  <Content className="formLabel">最終ログイン</Content>
                </Col>
                <Col span={formSpanParam}>
                  <Form.Item name="last_login_at">
                    <Input size="large" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={rowGutterParam} className="rowStyle">
                <Col span={labelSpanParam} className="colStyle">
                  <Content className="formLabel">パスワード</Content>
                </Col>
                <Col span={formSpanParam}>
                  <Form.Item name="password">
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={rowGutterParam} className="rowStyle">
                <Col span={labelSpanParam} className="colStyle">
                  <Content className="formLabel">再入力</Content>
                </Col>
                <Col span={formSpanParam}>
                  <Form.Item
                    name="password_confirm"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('パスワードと異なる入力になっています'));
                        },
                      }),
                    ]}>
                    <Input.Password size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="userButton">
                  編集
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" danger htmlType="button" className="userButton" onClick={showModal}>
                  削除
                </Button>
                <Modal
                  visible={isModalVisible}
                  onOk={modalDeleteOk}
                  onCancel={modalCancel}
                  footer={[
                    <Button key="delete" type="primary" danger onClick={modalDeleteOk}>
                      はい
                    </Button>,
                    <Button key="cancel" onClick={modalCancel}>
                      いいえ
                    </Button>,
                  ]}>
                  <p>本当に削除しますか？</p>
                </Modal>
              </Form.Item>
              <Form.Item>
                <Button htmlType="button" className="userButton" onClick={toUserHome}>
                  戻る
                </Button>
              </Form.Item>
            </Row>
          </Form>
        ) : (
          <Loading />
        )}
      </Row>
    </Content>
  );
};

export default UserEditComponent;
