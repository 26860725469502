import { Layout, Menu, message } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { authApi } from '../../api/apiClient';
import { useCommitLogout } from '../../store/useAuthStore';

const { Header } = Layout;

const HeaderComponent: React.FC = () => {
  const navigate = useNavigate();
  const commitLogout = useCommitLogout();
  const [current, setCurrent] = useState('');
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    if (pathname === '/') {
      setCurrent('search');
    } else {
      setCurrent(pathname.slice(1));
    }
  }, [pathname]);
  const onClick = async (e: { key: string }): Promise<void> => {
    if (e.key === 'logout') {
      await authApi.authControllerLogout();
      commitLogout();
      await message.success('ログアウトに成功しました。');
    } else if (e.key === 'logo') {
      navigate('/');
    } else {
      navigate(`/${e.key}`);
    }
  };

  const selectedMenu = {
    backgroundColor: '#2699FB',
    color: 'white',
    padding: '0px 30px',
  };
  const notSelectedMenu = {
    backgroundColor: '#7FC4FD',
    padding: '0px 30px',
  };
  const items = [
    {
      key: 'logo',
      icon: <img src={`${process.env.PUBLIC_URL}/sengikyo-logo.png`} alt="sengikyo-logo" style={{ height: '60px' }} />,
      style: { backgroundColor: '#7FC4FD', color: 'white', borderBottom: 'none', transform: 'translate(4px, -2px)' },
    },
    { label: '資料検索', key: 'search', style: current.startsWith('search') ? selectedMenu : notSelectedMenu },
    { label: '資料管理', key: 'pdf', style: current.startsWith('pdf') ? selectedMenu : notSelectedMenu },
    { label: '辞書管理', key: 'synonym', style: current.startsWith('synonym') ? selectedMenu : notSelectedMenu },
    {
      label: 'ユーザー管理',
      key: 'user',
      style: current.startsWith('user') ? selectedMenu : notSelectedMenu,
    },
    {
      key: 'userIcon',
      icon: <UserOutlined style={{ fontSize: '24px' }} />,
      style: { marginRight: '0', marginLeft: 'auto' },
      children: [{ label: 'ログアウト', key: 'logout' }],
    },
  ];

  return (
    <Header style={{ backgroundColor: '#7FC4FD', paddingRight: '0px' }}>
      <Menu
        mode="horizontal"
        selectedKeys={[current]}
        style={{ backgroundColor: '#7FC4FD', color: 'white', borderBottom: 'none' }}
        items={items}
        onClick={(e) => onClick(e)}
      />
    </Header>
  );
};

export default HeaderComponent;
