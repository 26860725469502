import { Moment } from 'moment';
import { SearchResponse } from '../generated-api';

export const filterByAttributes = (searchResponse: SearchResponse, attributeIds: string[]) => {
  if (attributeIds.length === 0) {
    return searchResponse;
  }
  const filtered = searchResponse.result.filter((pdfGroup) => {
    const arr = pdfGroup.attributes.map((attr) => attr.id).concat(attributeIds);
    const set = new Set(arr);
    return arr.length !== set.size;
  });
  return { hit_count: filtered.length, result: filtered, related_queries: searchResponse.related_queries };
};

export const filterByDateRange = (searchResponse: SearchResponse, dateRange: [Moment | null, Moment | null] | null) => {
  if (dateRange == null) {
    return searchResponse;
  }
  const [since, until] = dateRange;
  if (since == null && until == null) {
    return searchResponse;
  }
  if (since == null && until != null) {
    const filtered = searchResponse.result.filter((pdfGroup) => pdfGroup.pdf_created_at < until.format('YYYY-MM-DD'));
    return { hit_count: filtered.length, result: filtered, related_queries: searchResponse.related_queries };
  }
  if (since != null && until == null) {
    const filtered = searchResponse.result.filter((pdfGroup) => pdfGroup.pdf_created_at > since.format('YYYY-MM-DD'));
    return { hit_count: filtered.length, result: filtered, related_queries: searchResponse.related_queries };
  }
  const filtered = searchResponse.result.filter(
    (pdfGroup) =>
      pdfGroup.pdf_created_at > since!.format('YYYY-MM-DD') && pdfGroup.pdf_created_at < until!.format('YYYY-MM-DD')
  );
  return { hit_count: filtered.length, result: filtered, related_queries: searchResponse.related_queries };
};
