import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Link from 'antd/lib/typography/Link';
import axios from 'axios';
import HeaderComponent from '../components/common/HeaderComponent';
import PdfTopDeleteButton from '../components/common/button/PdfTopDeleteButton';
import PdfTopEditButton from '../components/common/button/PdfTopEditButton';
import PdfEditModal from '../components/PdfEditModal';
import validateMessages from '../utils/validateMessages';
import { attributeApi, pdfApi } from '../api/apiClient';
import { AttributeItem, PdfResponse, PostPdfRequest } from '../generated-api';
import Loading from './Loading';
import { UploadPdfForm } from '../models/pdf';
import '../styles/common.css';
import '../styles/table.css';
import { dateTimeFormatPdfUpdateAt } from '../utils/dateUtils';

const PdfManagement: React.FC = () => {
  const rowGutterParam = 24;
  const labelSpanParam = 8;
  const formSpanParam = 14;
  const { Dragger } = Upload;
  const { Content } = Layout;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [fileValue, setFileValue] = useState<File | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingId, setEditingId] = useState('');
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  // 削除する項目のid(number型)の情報をモーダルを経由しても保持できるようにするstate
  const [deletePdfName, setDeletePdfName] = useState<string>('-1');
  const [pdfList, setPdfList] = useState<PdfResponse[]>([]);
  // 選択可能な資料特徴の配列
  const [attributeList, setAttributeList] = useState<AttributeItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // pdfListのfetchはできているが、描画が完了していないという数秒間に、Loading画面を出すかPDF未登録メッセージを出すかを判定するstate
  const [isPdfListEmpty, setIsPdfListEmpty] = useState<boolean>(false);
  const [editFormValue, setEditFormValue] = useState<PdfResponse>({
    file_name: '',
    file_path: '',
    attributes: [{ word: '', id: '' }],
    pdf_created_by: '',
    pdf_created_at: '',
    id: '',
    pdf_deleted_by: '',
    pdf_deleted_at: '',
    company_id: '',
    updated_at: '',
    updated_by: '',
    uploaded_by: '',
    status: 'WAITING',
  });
  const navigate = useNavigate();
  const tableStyle = {
    margin: '24px 0px auto',
    border: '1px #bfbfbf solid',
  };

  useEffect(() => {
    setIsLoading(true);
    // pdfに関する情報（ファイル名・資料特徴等）を取得する処理
    const fetchPdfList = async () => {
      const pdfs = await pdfApi.pdfControllerGetPdfs();
      const formattedPdfList = dateTimeFormatPdfUpdateAt(pdfs.data);
      setPdfList(formattedPdfList);
      setIsPdfListEmpty(!formattedPdfList.length);
    };
    fetchPdfList().catch(console.error);

    // 選択可能な資料特徴を取得する処理
    const fetchAttributes = async (): Promise<void> => {
      const getAttributes = await attributeApi.attributeControllerGetAttributes();
      setAttributeList([
        ...getAttributes.data
          .filter((x) => !x.deleted_at)
          .map((y) => ({ id: y.id, word: y.word }))
          .sort((a, b) => a.word.localeCompare(b.word)),
      ]);
    };
    fetchAttributes()
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  const showDeleteModal = (recordId: string) => {
    setDeletePdfName(recordId);
    setModalVisible(true);
  };
  const modalDeleteOk = async (recordId: string): Promise<void> => {
    setModalVisible(false);
    setDeletePdfName('-1');
    try {
      await pdfApi.pdfControllerDeletePdf(recordId);
      await message.info('データが削除されました。');
      const fetchPdfList = await pdfApi.pdfControllerGetPdfs();
      const formattedPdfList = dateTimeFormatPdfUpdateAt(fetchPdfList.data);
      setPdfList(formattedPdfList);
    } catch (e) {
      await message.error('削除に失敗しました。');
    }
  };
  const modalDeleteCancel = () => {
    setModalVisible(false);
  };
  const editPdf = async (record: PdfResponse): Promise<void> => {
    const pdfData = await pdfApi.pdfControllerGetPdfById(record.id);
    setEditFormValue(pdfData.data);
    setEditModalVisible(true);
    setEditingId(record.id);
  };
  const toAttributeRegisterPage = () => {
    navigate('/pdf/attribute');
  };
  const toPdfUploadPage = () => {
    setUploadModalVisible(true);
  };

  const uploadModalCancel = () => {
    setUploadModalVisible(false);
    setFileValue(null);
    form.resetFields();
    navigate('/pdf');
  };

  // アップロードモーダルから「登録」押下時に呼ばれる
  const onFinish = async (values: UploadPdfForm) => {
    try {
      if (!fileValue) {
        await message.error('ファイルを選択してください');
        return;
      }
      const fieldAttribute: Array<AttributeItem> = [];

      if (values.attributes) {
        values.attributes.forEach((attribute) => {
          const matchAttribute = attributeList.filter((a) => a.word === attribute);
          fieldAttribute.push(matchAttribute[0]);
        });
      }
      const updatePdfValues: PostPdfRequest = {
        attributes: fieldAttribute,
        file_name: values.file_name,
        pdf_created_at: values.pdf_created_at ? values.pdf_created_at.format('YYYY-MM-DD') : '',
        pdf_created_by: values.pdf_created_by ? values.pdf_created_by : '',
      };
      setUploadModalVisible(false);
      // pdfデータを保存
      const pdfRes = await pdfApi.pdfControllerPostPdf(updatePdfValues);
      const uploadFilePath = pdfRes.data.file_path;
      // pdfファイルを格納するS3のURL取得
      const s3UrlResponse = await pdfApi.pdfControllerGetPresignedUrl({
        file_path: uploadFilePath,
        operation: 'putObject',
      });
      const s3Url = s3UrlResponse.data.presigned_url;
      await axios.put(s3Url, fileValue, {
        headers: {
          'Content-Type': fileValue.type,
        },
      });

      navigate('/pdf');
      await message.info('アップロードが完了しました。');
      const fetchPdfList = await pdfApi.pdfControllerGetPdfs();
      const formattedPdfList = dateTimeFormatPdfUpdateAt(fetchPdfList.data);
      setPdfList(formattedPdfList);
      setFileValue(null);
    } catch (e) {
      await message.error('アップロードに失敗しました。');
    }
  };

  // 選択したPDFファイルを消去する処理
  const removePdf = () => {
    setFileValue(null);
  };

  // PDFファイルを選択したときの処理
  const beforeUpload = async (file: File) => {
    const isPDF = file.type === 'application/pdf';
    if (!isPDF) {
      await message.error(`${file.name} is not a pdf file`);
    } else {
      setFileValue(file);
    }
    // 手動でアップロードするためにfalseを返す(antdは画像をuploadにセットするとPOSTが呼ばれてしまうのでそれを防ぐ)
    return false;
  };

  const columns = [
    {
      title: 'ファイル名',
      dataIndex: 'file_name',
      width: '20%',
      sorter: (a: PdfResponse, b: PdfResponse) => (a.file_name.toLowerCase() > b.file_name.toLowerCase() ? 1 : -1),
    },
    {
      title: '資料特徴',
      dataIndex: 'attributes',
      width: '20%',
      render: (text: string, record: PdfResponse) => (
        <span>
          {record.attributes.map((attribute) => (
            <Tag key={attribute.id}>{attribute.word}</Tag>
          ))}
        </span>
      ),
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      width: '10%',
      render: (text: string, record: PdfResponse): JSX.Element => (
        <span>
          {record?.status === 'PROCESSING' && <Tag color="green">処理中</Tag>}
          {record?.status === 'WAITING' && <Tag color="blue">待機中</Tag>}
          {record?.status === 'DONE' && <Tag color="magenta">完了</Tag>}
        </span>
      ),
    },
    {
      title: '資料作成日',
      dataIndex: 'pdf_created_at',
      width: '15%',
      sorter: (a: PdfResponse, b: PdfResponse) => (a.pdf_created_at > b.pdf_created_at ? 1 : -1),
    },
    {
      title: 'アップロード日時',
      dataIndex: 'updated_at',
      width: '15%',
      sorter: (a: PdfResponse, b: PdfResponse) => (a.updated_at > b.updated_at ? 1 : -1),
    },
    {
      title: '',
      dataIndex: '',
      width: '10%',
      render: (_text: string, record: PdfResponse): JSX.Element => (
        <PdfTopEditButton record={record} editPdf={() => editPdf(record)} />
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: '10%',
      render: (_text: string, record: PdfResponse): JSX.Element => (
        <PdfTopDeleteButton
          record={record}
          isModalVisible={isModalVisible}
          deletePdfName={deletePdfName}
          showModal={showDeleteModal}
          modalDeleteOk={() => modalDeleteOk(record.id)}
          modalCancel={modalDeleteCancel}
        />
      ),
    },
  ];

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <Content style={{ margin: '0 50px auto' }}>
        <Row gutter={24} className="rowStyle">
          <Col span={16} />
          <Col span={8} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <Button type="primary" style={{ width: '150px', marginRight: '8%' }} onClick={toAttributeRegisterPage}>
              資料特徴登録
            </Button>
            <Button type="primary" style={{ width: '150px' }} onClick={toPdfUploadPage}>
              アップロード
            </Button>
            <Modal visible={isUploadModalVisible} onCancel={uploadModalCancel} width={1000} footer={null}>
              <Content className="contentCenter">
                <Form
                  name="pdfUpload"
                  onFinish={(values: UploadPdfForm) => onFinish(values)}
                  autoComplete="off"
                  form={form}
                  validateMessages={validateMessages}>
                  <Card
                    className="cardStyleInDetail"
                    style={{
                      margin: 'auto',
                    }}>
                    <Dragger
                      multiple={false}
                      maxCount={1}
                      beforeUpload={(file) => beforeUpload(file)}
                      accept=".pdf"
                      showUploadList={false}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">ここにファイルをドロップまたは選択してください</p>
                    </Dragger>
                    <Row gutter={rowGutterParam} className="rowStyle">
                      <Col span={labelSpanParam} className="colStyle">
                        <Text>選択したファイル：</Text>
                      </Col>
                      <Col span={labelSpanParam} className="colStyle">
                        <Link href={fileValue === null ? '' : URL.createObjectURL(fileValue)} target="_blank">
                          {fileValue?.name}
                        </Link>
                      </Col>
                      <Col span={3} className="colStyle">
                        {fileValue?.name && (
                          <Popconfirm
                            title="選択したファイルを取り消しますか？"
                            onConfirm={async (): Promise<void> => {
                              removePdf();
                              await message.info('ファイルを取り消しました。');
                            }}
                            okText="はい"
                            cancelText="いいえ">
                            <Button type="primary" danger>
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={rowGutterParam} className="rowStyle">
                      <Col span={labelSpanParam} className="colStyle">
                        <Content className="formLabel">ファイル名</Content>
                      </Col>
                      <Col span={formSpanParam}>
                        <Form.Item name="file_name" rules={[{ required: true }]}>
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={rowGutterParam} className="rowStyle">
                      <Col span={labelSpanParam} className="colStyle">
                        <Content className="formLabel">資料特徴</Content>
                      </Col>
                      <Col span={formSpanParam}>
                        <Form.Item name="attributes">
                          <Select mode="multiple" size="large">
                            {attributeList.map((attribute) => (
                              <Select.Option value={attribute.word} key={attribute.id}>
                                {attribute.word}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={rowGutterParam} className="rowStyle">
                      <Col span={labelSpanParam} className="colStyle">
                        <Content className="formLabel">資料作成日</Content>
                      </Col>
                      <Col span={formSpanParam}>
                        <Form.Item name="pdf_created_at">
                          <DatePicker placeholder="日付を選択" size="large" style={{ width: '100%' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={rowGutterParam} className="rowStyle">
                      <Col span={labelSpanParam} className="colStyle">
                        <Content className="formLabel">作成者</Content>
                      </Col>
                      <Col span={formSpanParam}>
                        <Form.Item name="pdf_created_by">
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                  <Form.Item className="contentCenter" style={{ width: '100px', marginTop: '20px' }}>
                    <Button type="primary" htmlType="submit" block>
                      登録
                    </Button>
                  </Form.Item>
                </Form>
              </Content>
            </Modal>
          </Col>
        </Row>
        {(() => {
          if (isLoading || !pdfList.length) {
            if (isPdfListEmpty) {
              return <Alert message="資料が見つかりませんでした。" type="warning" showIcon />;
            }
            return <Loading />;
          }
          return (
            <Table
              rowKey={(record: PdfResponse) => record?.id}
              dataSource={pdfList}
              columns={columns}
              rowClassName="tableRowLight"
              style={tableStyle}
              pagination={{
                position: ['bottomCenter'],
                total: pdfList.length,
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range): string => `${range[0]}-${range[1]} of ${total} items`,
              }}
            />
          );
        })()}
      </Content>
      <PdfEditModal
        attributeList={attributeList}
        editFormValue={editFormValue}
        isEditModalVisible={isEditModalVisible}
        setEditModalVisible={setEditModalVisible}
        editingId={editingId}
        setPdfList={setPdfList}
      />
    </Layout>
  );
};

export default PdfManagement;
