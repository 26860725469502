import { Layout } from 'antd';
import React from 'react';
import HeaderComponent from '../components/common/HeaderComponent';
import SynonymAddComponent from '../components/SynonymAdd';

const SynonymEdit: React.FC = () => (
  <Layout style={{ backgroundColor: 'white' }}>
    <HeaderComponent />
    <SynonymAddComponent />
  </Layout>
);
export default SynonymEdit;
