import { Spin } from 'antd';

const Loading = () => (
  <div>
    <div
      style={{
        margin: '130px 0',
        textAlign: 'center',
      }}>
      <Spin size="large" />
    </div>
  </div>
);

export default Loading;
