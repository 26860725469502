import { Button, Card, Form, Input, Layout, Row, Col, Modal, FormInstance, message, Typography } from 'antd';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { attributeApi } from '../api/apiClient';
import { AttributeItem, AttributeModel } from '../generated-api';
import { AttributeFormType } from '../models/attribute';
import { IErrorResponse } from '../models/other';
import Loading from '../pages/Loading';
import '../styles/common.css';
import validateMessages from '../utils/validateMessages';

const rowGutterParam = 24;
const formSpanParam = 10;

const PdfAttributeComponent: React.FC<{
  attributeList: AttributeModel[];
  setAttributeList: React.Dispatch<React.SetStateAction<AttributeModel[]>>;
  isLoading: boolean;
}> = ({ attributeList, setAttributeList, isLoading }) => {
  const [isStoredItemModalVisible, setIsStoredItemModalVisible] = useState(false);
  const [editingAttributeKey, setEditingAttributeKey] = useState<number>(-1);
  const navigate = useNavigate();
  const [form] = Form.useForm<AttributeFormType>();
  // 削除する項目のkeyの情報をモーダルを経由しても保持できるようにするstate(keyは他の資料特徴が削除されてもfetch時に振られた値から不変)
  const [deleteAttributeKey, setDeleteAttributeKey] = useState<number>(-1);
  // 「編集」ボタン押下時に入力欄に入っていた値
  const [beforeEditWord, setBeforeEditWord] = useState<AttributeItem>({ id: '', word: '' });
  const { Title } = Typography;

  useEffect(() => form.resetFields(), [attributeList, form]);

  // 資料特徴の編集中にほかの「編集」ボタン等（「完了」以外のボタン）を押下した場合、編集前の資料特徴に戻す
  const revertEditingWord = (): void => {
    if (editingAttributeKey !== -1) {
      const newStoredAttributes = form.getFieldsValue().storedAttributes;
      newStoredAttributes[editingAttributeKey] = beforeEditWord;
      form.setFieldsValue({ storedAttributes: newStoredAttributes });
      setEditingAttributeKey(-1);
    }
  };

  // 各単語横の編集ボタン押下時
  const edit = (fieldKey: number) => {
    revertEditingWord();
    const currentWord = form.getFieldsValue().storedAttributes[fieldKey];
    setBeforeEditWord(currentWord);
    setEditingAttributeKey(fieldKey);
  };

  // 各単語横の完了ボタン押下時
  const editComplete = async (attributeKey: number) => {
    const currentWord = form.getFieldsValue().storedAttributes[attributeKey];
    // onFinishを使ったバリデーション実装ができなかったため、エラーメッセージは以下のように出している（できなかった理由：「完了」ボタンにsubmit属性を付与すると同じ位置にある「編集」ボタンを押下した際にもsubmitされてしまうようである）
    if (!currentWord.word) {
      await message.error('編集後の資料特徴を入力をしてください');
      return;
    }
    const updateAttribute = { word: currentWord.word };
    try {
      await attributeApi.attributeControllerUpdateAttribute(currentWord.id, updateAttribute);
      setEditingAttributeKey(-1);
      await message.success(`編集が完了し、${currentWord.word}が追加されました。`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response?.status === 409) {
          if ((e as AxiosError<IErrorResponse>).response?.data.message === 'word already exists') {
            await message.error('既に存在する資料特徴を登録することはできません');
          }
        }
        if (e.response && e.response?.status === 403) {
          if ((e as AxiosError<IErrorResponse>).response?.data.message === 'word is deleted') {
            await message.error('既に削除された資料特徴に編集することはできません');
          }
          if ((e as AxiosError<IErrorResponse>).response?.data.message === 'not allowed') {
            await message.error('ほかの会社の資料特徴を修正することはできません');
          }
        }
      } else {
        await message.error('資料特徴登録に失敗しました。');
      }
    }
  };

  // 新規追加資料特徴の登録ボタン押下時
  const addAttribute = async () => {
    const currentWord = form.getFieldsValue().newAttribute;
    if (!currentWord) {
      await message.error('登録する資料特徴を入力をしてください');
      return;
    }
    const registerAttribute = { word: currentWord };
    try {
      const newAttribute = await attributeApi.attributeControllerPostAttribute(registerAttribute);
      // 新規追加した資料特徴をattributeListに加える
      const newAttributes = [...attributeList];
      newAttributes.push(newAttribute.data);
      setAttributeList(newAttributes);
      await message.success(`${currentWord}が追加されました。`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response?.status === 409) {
          if ((e as AxiosError<IErrorResponse>).response?.data.message === 'word already exists') {
            await message.error('既に存在する資料特徴を登録することはできません');
          }
        }
      } else {
        await message.error('資料特徴登録に失敗しました。');
      }
    }
  };
  // 各単語横の削除ボタン押下時
  const showModal = (fieldKey: number) => {
    revertEditingWord();
    setDeleteAttributeKey(fieldKey);
    setIsStoredItemModalVisible(true);
  };
  // 削除モーダルから削除実行時
  const modalDeleteOk = async (attributeKey: number, isStoredAttribute: boolean) => {
    setIsStoredItemModalVisible(false);
    if (isStoredAttribute) {
      try {
        const deleteAttribute = attributeList[attributeKey];
        await attributeApi.attributeControllerDeleteAttribute(deleteAttribute.id);
        // 削除した資料特徴をattributeListから消す
        const newAttributes = [...attributeList];
        newAttributes.splice(attributeKey, 1);
        setAttributeList(newAttributes);
        await message.success(`${deleteAttribute.word}が削除されました。`);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response && e.response?.status === 404) {
            if ((e as AxiosError<IErrorResponse>).response?.data.message === "linked pdf exists.couldn't DELETE") {
              await message.error('削除しようとした資料特徴はpdfに紐づいているため削除できません。');
            }
          }
        } else {
          await message.error('資料特徴削除に失敗しました。');
        }
      }
    }
  };
  const modalCancel = () => {
    setIsStoredItemModalVisible(false);
  };
  const backToPdfTop = () => {
    navigate('/pdf');
  };

  const FormRef = useRef<FormInstance>(null);

  const { Content } = Layout;
  return (
    <Content className="contentCenter">
      <Form
        name="pdfAttribute"
        form={form}
        ref={FormRef}
        autoComplete="off"
        colon={false}
        validateMessages={validateMessages}>
        <Row gutter={24}>
          <Card className="cardStyleInDetail" style={{ overflow: 'auto' }}>
            <Title level={3} style={{ marginBottom: '32px' }}>
              資料特徴登録画面
            </Title>
            {(() => {
              if (isLoading) {
                return <Loading />;
              }
              return (
                <div>
                  <Form.List name="storedAttributes" initialValue={attributeList}>
                    {(fields): JSX.Element => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Row gutter={rowGutterParam} className="rowStyle" key={key}>
                            <Col span={formSpanParam}>
                              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                              <Form.Item {...restField} name={[name, 'word']}>
                                <Input disabled={editingAttributeKey !== key} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item>
                                {editingAttributeKey !== key ? (
                                  <Button type="primary" htmlType="button" onClick={() => edit(key)}>
                                    編集
                                  </Button>
                                ) : (
                                  <Button type="primary" htmlType="button" onClick={() => editComplete(key)}>
                                    完了
                                  </Button>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item>
                                <Button type="primary" danger htmlType="button" onClick={() => showModal(key)}>
                                  削除
                                </Button>
                                <Modal
                                  visible={isStoredItemModalVisible}
                                  mask={false}
                                  onCancel={modalCancel}
                                  footer={[
                                    <Button
                                      key="delete"
                                      type="primary"
                                      danger
                                      onClick={async () => {
                                        await modalDeleteOk(deleteAttributeKey, true);
                                      }}>
                                      はい
                                    </Button>,
                                    <Button key="cancel" onClick={modalCancel}>
                                      いいえ
                                    </Button>,
                                  ]}>
                                  <p>本当に削除しますか？</p>
                                </Modal>
                              </Form.Item>
                            </Col>
                            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                            <Form.Item {...restField} name={[name, 'id']} hidden>
                              <Input hidden />
                            </Form.Item>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                  <Row gutter={rowGutterParam} className="rowStyle">
                    <Col span={formSpanParam}>
                      <Form.Item className="contentCenter" name="newAttribute">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Button type="primary" htmlType="button" onClick={() => addAttribute()}>
                        登録
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })()}
          </Card>
        </Row>
        <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Form.Item>
            <Button htmlType="button" onClick={backToPdfTop}>
              戻る
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Content>
  );
};

export default PdfAttributeComponent;
