import { Form, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import HeaderComponent from '../components/common/HeaderComponent';
import SynonymEditComponent from '../components/SynonymEdit';
import { WordOfDictionary, SynonymFormType } from '../models/synonym';
import { synonymApi } from '../api/apiClient';

const SynonymEdit: React.FC = () => {
  const { id } = useParams();
  const [form] = Form.useForm<SynonymFormType>();
  const [synonyms, setSynonyms] = useState<WordOfDictionary>({
    company_id: '',
    group_id: '',
    words: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id !== undefined) {
      (async () => {
        setIsLoading(true);
        const fetchSynonyms = await synonymApi.synonymControllerGetSynonymByGroupId(id);
        setSynonyms(fetchSynonyms.data);
      })()
        .catch(() => {
          navigate('/synonym');
        })
        .finally(() => setIsLoading(false));
    }
  }, [id, navigate]);

  useEffect(() => {
    form.resetFields();
  }, [synonyms, form]);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <SynonymEditComponent synonymValues={synonyms} setSynonymValues={setSynonyms} form={form} isLoading={isLoading} />
    </Layout>
  );
};

export default SynonymEdit;
