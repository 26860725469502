import { Button, Card, Col, DatePicker, Form, Input, Modal, Row, Select, message, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { PDFPageProxy } from 'pdfjs-dist';
import PdfPreview from './common/PdfPreview';
import { UploadPdfForm } from '../models/pdf';
import validateMessages from '../utils/validateMessages';
import { AttributeItem, PdfResponse, UpdatePdfRequest } from '../generated-api';
import { pdfApi } from '../api/apiClient';
import { pdfLoad } from '../utils/pdfUtils';
import { dateTimeFormatPdfUpdateAt } from '../utils/dateUtils';

const PdfEditModal: React.FC<{
  attributeList: AttributeItem[];
  editFormValue: PdfResponse;
  isEditModalVisible: boolean;
  setEditModalVisible: (flg: boolean) => void;
  editingId: string;
  setPdfList: (record: PdfResponse[]) => void;
}> = (props) => {
  const { attributeList, editFormValue, isEditModalVisible, setEditModalVisible, editingId, setPdfList } = props;
  const rowGutterParam = 24;
  const labelSpanParam = 8;
  const formSpanParam = 14;
  const [form] = Form.useForm<UploadPdfForm>();
  const { Content } = Layout;

  const [result, setResult] = useState('');
  const [aspect, setAspect] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState<PDFPageProxy[]>([]);

  useEffect(() => {
    if (editFormValue.file_path) {
      setIsLoading(true);
      const getPdf = async () => {
        const getPresignedUrl = await pdfApi.pdfControllerGetPresignedUrl({
          file_path: editFormValue.file_path,
          operation: 'getObject',
        });
        await pdfLoad(getPresignedUrl.data.presigned_url, setTotalPage, setPages, setResult, setAspect, 1);
      };
      getPdf()
        .catch(console.error)
        .finally(() => setIsLoading(false));
      const attributesWords: Array<string> = [];
      editFormValue.attributes.forEach((value) => {
        attributesWords.push(value.word);
      });
      // stateのset関数での変更（initialValueを通じて）ではモーダル表示の際にformが切り替わらないようなのでformに直接入れるような形で
      // UploadPdfFormの型に整形(attributesをstring型の配列に、pdf_created_atをmoment型に直す)（いずれもantdの仕様上必要、selectはobjectを受け付けず、datepickerはmomentしか受け付けないため）
      form.setFieldsValue({
        file_name: editFormValue.file_name,
        attributes: attributesWords,
        pdf_created_at: moment(editFormValue.pdf_created_at),
        pdf_created_by: editFormValue.pdf_created_by,
      });
    }
  }, [editFormValue, form]);

  const editCancel = () => {
    setCurrentPage(1);
    form.resetFields();
    setEditModalVisible(false);
    setResult('');
  };

  const onFinish = async (values: UploadPdfForm) => {
    // UpdatePdfRequestの型に整形(attributesをAttributeItem型の配列に、pdf_created_atのmoment型をstring型に直す);
    const fieldAttribute: Array<AttributeItem> = [];
    values.attributes.forEach((attribute) => {
      const matchAttribute = attributeList.filter((a) => a.word === attribute);
      fieldAttribute.push(matchAttribute[0]);
    });
    const updateValues: UpdatePdfRequest = {
      attributes: fieldAttribute,
      file_name: values.file_name,
      pdf_created_at: values.pdf_created_at ? values.pdf_created_at.format('YYYY-MM-DD') : '',
      pdf_created_by: values.pdf_created_by ? values.pdf_created_by : '',
    };
    try {
      setCurrentPage(1);
      form.resetFields();
      setEditModalVisible(false);
      setResult('');
      await pdfApi.pdfControllerUpdatePdf(editingId, updateValues);
      await message.info('編集が完了しました。');
    } catch (e) {
      await message.info('編集が失敗しました。');
    }
    const fetchPdfList = await pdfApi.pdfControllerGetPdfs();
    const formattedPdfList = dateTimeFormatPdfUpdateAt(fetchPdfList.data);
    setPdfList(formattedPdfList);
  };

  return (
    <Modal
      visible={isEditModalVisible}
      onCancel={editCancel}
      width={1000}
      footer={null}
      forceRender
      getContainer={false}>
      <Content className="contentCenter">
        <Form
          name="pdfUpload"
          form={form}
          onFinish={(values: UploadPdfForm) => onFinish(values)}
          autoComplete="off"
          validateMessages={validateMessages}>
          <Card
            style={{
              width: '800px',
              height: '960px',
              border: '1px solid #a3a3a3',
              padding: '0px 30px',
              margin: 'auto',
            }}>
            <PdfPreview
              result={result}
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setResult={setResult}
              isLoading={isLoading}
              pages={pages}
              aspect={aspect}
              setAspect={setAspect}
            />
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="colStyle">
                <Content className="formLabel">ファイル名</Content>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item name="file_name" rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="colStyle">
                <Content className="formLabel">資料特徴</Content>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item name="attributes">
                  <Select mode="multiple" size="large">
                    {attributeList.map((attribute) => (
                      <Select.Option value={attribute.word} key={attribute.id}>
                        {attribute.word}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="colStyle">
                <Content className="formLabel">資料作成日</Content>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item name="pdf_created_at">
                  <DatePicker placeholder="日付を選択" size="large" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="colStyle">
                <Content className="formLabel">作成者</Content>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item name="pdf_created_by">
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Form.Item className="contentCenter" style={{ width: '100px', marginTop: '20px' }}>
            <Button type="primary" htmlType="submit" block>
              更新
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Modal>
  );
};

export default PdfEditModal;
