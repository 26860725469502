import axios from 'axios';
import { getDocument, GlobalWorkerOptions, PDFPageProxy } from 'pdfjs-dist';

// 何らかの手段(ローカルではyarn start時にコピー)で node_modules/pdfjs-dist/build/pdf.worker.min.jsに置いてあるWebWorker ファイルを "バンドルせずに" 静的に設置する必要がある
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export const pdfRender = async (
  pages: PDFPageProxy[],
  currentPage: number,
  setResult: React.Dispatch<React.SetStateAction<string>>,
  setAspect: React.Dispatch<React.SetStateAction<number>>
) => {
  if (pages.length) {
    const page = pages[currentPage - 1];
    const viewport = page.getViewport({
      scale: 2.0,
    });
    const canvas = document.createElement('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const ctx = canvas.getContext('2d');
    if (ctx == null) {
      throw new Error('getContext failed.');
    }
    await page.render({
      canvasContext: ctx,
      viewport,
    }).promise; // .promise を付けて await しないと実行されないので注意

    // canvas に結果が描画されている
    setResult(canvas.toDataURL());
    setAspect(canvas.height / canvas.width);
  }
};

export const pdfLoad = async (
  pdfSearchUrl: string,
  setTotalPage: React.Dispatch<React.SetStateAction<number>>,
  setPages: React.Dispatch<React.SetStateAction<PDFPageProxy[]>>,
  setResult: React.Dispatch<React.SetStateAction<string>>,
  setAspect: React.Dispatch<React.SetStateAction<number>>,
  initialPage: number
) => {
  if (pdfSearchUrl) {
    const doc = await getDocument(pdfSearchUrl).promise; // PDFの読み込み await するには .promise がいる
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, no-underscore-dangle
    setTotalPage(doc._pdfInfo.numPages);
    let pdfPages = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, no-underscore-dangle
    for (let i = 1; i <= doc._pdfInfo.numPages; i += 1) {
      pdfPages.push(doc.getPage(i));
    }
    pdfPages = await Promise.all(pdfPages);
    setPages(pdfPages);
    // loadの中で最初に表示するページのみを描画しておく(これがないとpdfRenderにおいてpages.length===0となるため、resultに値が入らず一瞬画像がない状態になる)
    await pdfRender(pdfPages, initialPage, setResult, setAspect);
  }
};

export const pdfDownload = async (fileName: string, presignedUrl: string) => {
  try {
    const response = await axios({
      url: presignedUrl,
      method: 'GET',
      responseType: 'blob',
      headers: { Accept: 'application/pdf' },
    });
    const uri = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.download = `${fileName}.pdf`;
    link.href = uri;
    link.click();
  } catch (e) {
    if (axios.isAxiosError(e)) {
      console.log(e);
    }
  }
};
