import { Button, Card, Form, Input, Layout, Row, Col, Modal, FormInstance, Typography, message } from 'antd';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { synonymApi } from '../api/apiClient';
import '../styles/common.css';
import validateMessages from '../utils/validateMessages';

const rowGutterParam = 24;
const formSpanParam = 10;

export interface SynonymAddForm {
  firstSynonym: string;
  synonymsToAdd: string[];
}

const SynonymAdd: React.FC = () => {
  const [isItemModalVisible, setItemModalVisible] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // 削除する項目のfieldName(number型)の情報をモーダルを経由しても保持できるようにするstate
  const [deleteSynonymName, setDeleteSynonymName] = useState<number>(-1);
  const { Title } = Typography;

  const onFinish = async (fieldsValue: SynonymAddForm) => {
    try {
      let createSynonyms: { words: string[] };
      if (fieldsValue.synonymsToAdd) {
        const newWords = fieldsValue.synonymsToAdd.slice(0, fieldsValue.synonymsToAdd.length);
        newWords.unshift(fieldsValue.firstSynonym);
        createSynonyms = { words: newWords };
      } else {
        createSynonyms = { words: [fieldsValue.firstSynonym] };
      }
      await synonymApi.synonymControllerPostNewSynonym(createSynonyms);
      await message.success(`追加が成功しました。`);
      navigate('/synonym');
    } catch (e) {
      await message.error(`追加に失敗しました。`);
    }
  };
  const showModal = (fieldName: number) => {
    setDeleteSynonymName(fieldName);
    setItemModalVisible(true);
  };
  const modalDeleteOk = () => {
    setItemModalVisible(false);
  };
  const modalCancel = () => {
    setItemModalVisible(false);
  };
  const backToSynonymHome = () => {
    navigate('/synonym');
  };

  const FormRef = useRef<FormInstance>(null);

  const { Content } = Layout;
  return (
    <Content className="contentCenter">
      <Form
        name="synonymAdd"
        form={form}
        onFinish={(values: SynonymAddForm) => onFinish(values)}
        ref={FormRef}
        autoComplete="off"
        colon={false}
        validateMessages={validateMessages}>
        <Row gutter={24}>
          <Card className="cardStyleInDetail" style={{ overflow: 'auto' }}>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={formSpanParam}>
                <Title level={5}>新規単語</Title>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item
                  name="firstSynonym"
                  rules={[
                    { required: true, message: '新規単語を入力してください。' },
                    {
                      pattern: /^(?!.*,).*$/,
                      message: '半角コンマを含む文字は登録できません。',
                    },
                  ]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="synonymsToAdd">
              {(fields, { add, remove }): JSX.Element => (
                <>
                  {fields.map((field) => (
                    <Row gutter={rowGutterParam} className="rowStyle" key={field.key}>
                      <Col span={formSpanParam}>
                        <Title level={5}>追加する類義語</Title>
                      </Col>
                      <Col span={formSpanParam}>
                        <Form.Item
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          rules={[
                            { required: true, message: '追加する類義語を入力してください。' },
                            {
                              pattern: /^(?!.*,).*$/,
                              message: '半角コンマを含む文字は登録できません。',
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item>
                          <Button type="primary" danger htmlType="button" onClick={() => showModal(field.name)}>
                            削除
                          </Button>
                          <Modal
                            visible={isItemModalVisible}
                            onOk={modalDeleteOk}
                            onCancel={modalCancel}
                            footer={[
                              <Button
                                key="delete"
                                type="primary"
                                danger
                                onClick={() => {
                                  remove(deleteSynonymName);
                                  modalDeleteOk();
                                }}>
                                はい
                              </Button>,
                              <Button key="cancel" onClick={modalCancel}>
                                いいえ
                              </Button>,
                            ]}>
                            <p>本当に削除しますか？</p>
                          </Modal>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item className="contentCenter" style={{ width: '100px' }}>
                    <Button
                      type="primary"
                      onClick={(): void => {
                        add();
                      }}
                      block>
                      類義語追加
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Card>
        </Row>
        <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              登録
            </Button>
          </Form.Item>
          <Form.Item>
            <Button htmlType="button" onClick={backToSynonymHome}>
              戻る
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Content>
  );
};

export default SynonymAdd;
