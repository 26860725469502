import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { userApi } from '../api/apiClient';

type MeState = {
  user_id: string;
  company_id: string;
};

const meState = atom<MeState>({
  key: 'MeStore/meState',
  default: {
    user_id: '',
    company_id: '',
  },
});

export const useMeStore = (): MeState => useRecoilValue(meState);

export const useFetchMe = (): (() => Promise<void>) => {
  const setMe = useSetRecoilState(meState);

  return useCallback(async () => {
    const { data } = await userApi.userControllerGetMe();

    setMe({
      user_id: data.user_id,
      company_id: data.company_id,
    });
  }, [setMe]);
};
