import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import HeaderComponent from '../components/common/HeaderComponent';
import SynonymListComponent from '../components/SynonymList';
import { WordOfDictionary } from '../models/synonym';
import { synonymApi } from '../api/apiClient';

const SynonymList: React.FC = () => {
  const [synonymsList, setSynonymsList] = useState<WordOfDictionary[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 類義語一覧を取得する処理
  useEffect(() => {
    const fetchSynonymsList: () => Promise<void> = async () => {
      setIsLoading(true);
      const getSynonymsList = await synonymApi.synonymControllerGetSynonyms();
      setSynonymsList(getSynonymsList.data);
    };
    fetchSynonymsList()
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <SynonymListComponent synonymsList={synonymsList} isLoading={isLoading} />
    </Layout>
  );
};

export default SynonymList;
