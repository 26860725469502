import axios from 'axios';

import {
  AuthApi,
  PdfApi,
  SearchLogApi,
  UserApi,
  AttributeApi,
  SynonymApi,
  SearchApi,
  CompanyApi,
} from '../generated-api';

const options = {
  isJsonMime: (mime: string) => mime === 'application/json',
  headers: {
    'Content-Type': 'application/json',
  },
};
// let axiosInterceptorId = 0;

let endpoint;
if (process.env.REACT_APP_STAGE === 'prd') {
  endpoint = process.env.REACT_APP_API_PRD_ENDPOINT;
} else if (process.env.REACT_APP_STAGE === 'dev') {
  endpoint = process.env.REACT_APP_API_DEV_ENDPOINT;
} else if (process.env.REACT_APP_STAGE === 'local') {
  endpoint = process.env.REACT_APP_API_LOCAL_ENDPOINT;
} else {
  endpoint = process.env.REACT_APP_API_LOCAL_ENDPOINT;
}

export const apiClientRaw = axios.create({
  baseURL: endpoint,
  withCredentials: true,
});

// initAxiosInterceptor();

export const authApi = new AuthApi(options, '', apiClientRaw);
export const pdfApi = new PdfApi(options, endpoint, apiClientRaw);
export const searchLogApi = new SearchLogApi(options, endpoint, apiClientRaw);
export const userApi = new UserApi(options, endpoint, apiClientRaw);
export const attributeApi = new AttributeApi(options, endpoint, apiClientRaw);
export const synonymApi = new SynonymApi(options, endpoint, apiClientRaw);
export const searchApi = new SearchApi(options, endpoint, apiClientRaw);
export const companyApi = new CompanyApi(options, endpoint, apiClientRaw);

// function initAxiosInterceptor(): void {
//   if (axiosInterceptorId !== 0) {
//     return;
//   }
//   axiosInterceptorId = apiClientRaw.interceptors.request.use((req) => {
//     if (!req.url?.endsWith('/auth/login')) {
//       const token = localStorage.getItem('token');
//       req.headers['Authorization'] = `Bearer ${token}`;
//     }
//     return req;
//   });
// }
