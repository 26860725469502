import { Form, Row } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { PDFPageProxy } from 'pdfjs-dist';
import React, { useEffect, useState } from 'react';
import { pdfRender } from '../utils/pdfUtils';
import PdfReading from './common/PdfReading';

const SearchDetailComponent: React.FC<{
  totalPage: number;
  pages: PDFPageProxy[];
  isLoading: boolean;
  result: string;
  setResult: React.Dispatch<React.SetStateAction<string>>;
  aspect: number;
  setAspect: React.Dispatch<React.SetStateAction<number>>;
  initialPage: number;
}> = (props) => {
  const { totalPage, pages, isLoading, result, setResult, aspect, setAspect, initialPage } = props;
  const rowGutterParam = 24;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [form] = Form.useForm();

  useEffect(() => {
    const f = async () => {
      await pdfRender(pages, currentPage, setResult, setAspect);
    };
    f().catch(console.error);
  }, [pages, currentPage, setResult, setAspect]);

  return (
    <Content className="contentCenter">
      <Row gutter={rowGutterParam}>
        <Form name="pdfReading" form={form} autoComplete="off">
          <PdfReading
            result={result}
            totalPage={totalPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            form={form}
            isLoading={isLoading}
            aspect={aspect}
          />
        </Form>
      </Row>
    </Content>
  );
};

export default SearchDetailComponent;
