import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from 'antd';
import { Moment } from 'moment';
import { Link } from 'react-router-dom';
import { AttributeModel } from '../generated-api';
import {
  SEARCH_TARGET_COMPANY,
  SEARCH_TARGET_ASSOCIATION,
  Attribute,
  //   SearchTarget,
  SearchRequest,
  SearchFormInitialValues,
} from '../models/search';
import '../styles/common.css';

interface SearchFormProps {
  initialValues: SearchFormInitialValues;
  onSearch: (values: SearchRequest) => void;
  form: FormInstance<SearchRequest>;
  onAttributesChange: (attributeIds: string[]) => void;
  attributeList: AttributeModel[];
  onDateRangePick: (dates: [Moment | null, Moment | null] | null, datestrings: [string, string]) => void;
  onSortTypeChange: (value: string) => void;
  onIsExactMatchChange: ({ target: { value } }: RadioChangeEvent) => void;
}

const rowGutterParam = 24;
const SearchForm: React.FC<SearchFormProps> = (props) => {
  const { initialValues } = props;
  const { onSearch } = props;
  const { form } = props;
  const { onAttributesChange } = props;
  const { attributeList } = props;
  const { onDateRangePick } = props;
  const { RangePicker } = DatePicker;
  const { onSortTypeChange } = props;
  const { onIsExactMatchChange } = props;

  return (
    <Form
      name="searchPdf"
      form={form}
      onFinish={(values: SearchRequest) => onSearch(values)}
      initialValues={initialValues}>
      <Row gutter={rowGutterParam} className="rowStyle" style={{ height: '40px' }}>
        <Col span={12} className="searchAlignment">
          <Form.Item
            name="searchKeyword"
            style={{ width: '470px', marginRight: '16px' }}
            rules={[{ required: true, message: '検索フォームが空です' }]}>
            <Input />
          </Form.Item>
          <Form.Item style={{ marginRight: '16px' }}>
            <Button type="primary" htmlType="submit">
              検索
            </Button>
          </Form.Item>
          <div style={{ transform: 'translateY(-50%)', width: '170px' }}>
            <Link to="/search/log">検索ログ</Link>
          </div>
        </Col>
      </Row>
      <Row gutter={rowGutterParam} className="rowStyle" style={{ paddingLeft: '12px' }}>
        <Col span={18} className="searchAlignment">
          <Form.Item name="searchTarget">
            <Radio.Group>
              <Radio value={SEARCH_TARGET_COMPANY}>社内資料</Radio>
              <Radio value={SEARCH_TARGET_ASSOCIATION}>船技協</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="資料特徴" name="searchAttributes" style={{ marginRight: '16px' }}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '15em' }}
              placeholder="資料特徴を選択"
              onChange={onAttributesChange}>
              {attributeList.map((attribute: Attribute) => (
                <Select.Option key={attribute.id} value={attribute.id}>
                  {attribute.word}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="資料作成日" name="searchDateRange">
            <RangePicker allowEmpty={[true, true]} onChange={onDateRangePick} />
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: '0 0 12px 0' }} />
      <Row gutter={rowGutterParam} className="rowStyle" style={{ paddingLeft: '12px' }}>
        <Col span={18} className="searchAlignment">
          <Form.Item name="searchIsExactMatch">
            <Radio.Group onChange={(e) => onIsExactMatchChange(e)}>
              <Radio value={false}>部分一致</Radio>
              <Radio value>完全一致</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="表示順" name="sort">
            <Select
              onChange={onSortTypeChange}
              style={{ width: 180 }}
              options={[
                { value: 'SCORE', label: 'スコア順' },
                { value: 'DATE_ASC', label: '資料作成日(昇順)' },
                { value: 'DATE_DESC', label: '資料作成日(降順)' },
                { value: 'NAME_ASC', label: 'ファイル名(昇順)' },
                { value: 'NAME_DESC', label: 'ファイル名(降順)' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider style={{ margin: '0 0 12px 0' }} />
    </Form>
  );
};

export default SearchForm;
