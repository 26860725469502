import { Layout, Row, Col, Table, Button, Tag } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../pages/Loading';
import { UserResponse } from '../generated-api';
import '../styles/common.css';
import '../styles/table.css';

const UserListComponent: React.FC<{
  usersInfo: UserResponse[];
  isLoading: boolean;
}> = ({ usersInfo, isLoading }) => {
  const navigate = useNavigate();
  const tableStyle = {
    margin: '24px 0px auto',
    border: '1px #bfbfbf solid',
  };
  const onUserAddClick = () => {
    navigate('/user/new');
  };
  const onUserInfoClick = (userId: string) => {
    navigate(`/user/${userId}`);
  };
  const columns = [
    { title: 'ユーザ名', dataIndex: 'name' },
    { title: 'Email', dataIndex: 'email' },
    { title: '最終ログイン', dataIndex: 'last_login_at' },
    {
      title: 'active',
      render: (record: UserResponse): JSX.Element => (
        <span>{record.deleted_at ? <Tag>非アクティブ</Tag> : <Tag color="blue">アクティブ</Tag>}</span>
      ),
    },
    {
      title: '',
      render: (record: UserResponse): JSX.Element => (
        <Button type="primary" onClick={() => onUserInfoClick(record.user_id)}>
          編集・削除
        </Button>
      ),
    },
  ];

  const { Content } = Layout;
  return (
    <Content style={{ margin: '0 50px auto' }}>
      <Row gutter={24} className="rowStyle">
        <Col span={16} />
        <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" style={{ width: '150px' }} onClick={onUserAddClick}>
            新規ユーザー登録
          </Button>
        </Col>
      </Row>
      {(() => {
        if (isLoading) {
          return (
            <Col span={24}>
              <Loading />;
            </Col>
          );
        }
        if (!usersInfo.length) {
          return null;
        }
        return (
          <Table
            dataSource={usersInfo}
            columns={columns}
            rowClassName={(record) => (record.deleted_at ? 'tableRowDark' : 'tableRowLight')}
            style={tableStyle}
            scroll={{ y: 600 }}
            rowKey="user_id"
            pagination={{
              position: ['bottomCenter'],
              total: usersInfo.length,
              defaultPageSize: 10,
              showSizeChanger: true,
              showTotal: (total, range): string => `${range[0]}-${range[1]} of ${total} users`,
            }}
          />
        );
      })()}
    </Content>
  );
};

export default UserListComponent;
