import { Button } from 'antd';
import React from 'react';
import { pdfApi } from '../../../api/apiClient';
import { pdfDownload } from '../../../utils/pdfUtils';

const PdfDownloadButton: React.FC<{ fileName: string; filePath: string }> = (props) => {
  const { fileName, filePath } = props;
  return (
    <Button
      type="primary"
      onClick={async () => {
        const getPresignedUrl = await pdfApi.pdfControllerGetPresignedUrl({
          file_path: filePath,
          operation: 'getObject',
        });
        await pdfDownload(fileName, getPresignedUrl.data.presigned_url);
      }}>
      ダウンロード
    </Button>
  );
};

export default PdfDownloadButton;
