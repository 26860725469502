import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { attributeApi } from '../api/apiClient';
import HeaderComponent from '../components/common/HeaderComponent';
import PdfAttributeComponent from '../components/PdfAttribute';
import { AttributeModel } from '../generated-api';

const PdfAttribute: React.FC = () => {
  const [attributeList, setAttributeList] = useState<AttributeModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 資料特徴を取得する処理
  useEffect(() => {
    setIsLoading(true);
    const fetchAttributes = async () => {
      const fetchAttributeList = await attributeApi.attributeControllerGetAttributes();
      const activeAttributeList = fetchAttributeList.data.filter((attribute) => !attribute.deleted_at);
      setAttributeList(activeAttributeList);
    };
    fetchAttributes()
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <PdfAttributeComponent attributeList={attributeList} setAttributeList={setAttributeList} isLoading={isLoading} />
    </Layout>
  );
};
export default PdfAttribute;
