import { SearchResponse } from '../generated-api';
import { SortType } from '../models/search';

const sortSearchResponse = (searchResponse: SearchResponse, sortType: SortType) => {
  switch (sortType) {
    case 'DATE_DESC': {
      const sortedResult = [...searchResponse.result].sort((a, b) => (a.pdf_created_at < b.pdf_created_at ? 1 : -1));
      return { ...searchResponse, result: sortedResult };
    }
    case 'DATE_ASC': {
      const sortedResult = [...searchResponse.result].sort((a, b) => (a.pdf_created_at > b.pdf_created_at ? 1 : -1));
      return { ...searchResponse, result: sortedResult };
    }
    case 'NAME_DESC': {
      const sortedResult = [...searchResponse.result].sort((a, b) => (a.file_name < b.file_name ? 1 : -1));
      return { ...searchResponse, result: sortedResult };
    }
    case 'NAME_ASC': {
      const sortedResult = [...searchResponse.result].sort((a, b) => (a.file_name > b.file_name ? 1 : -1));
      return { ...searchResponse, result: sortedResult };
    }
    case 'SCORE': {
      return searchResponse;
    }
    default:
      return searchResponse;
  }
};

export default sortSearchResponse;
