/* tslint:disable */
/* eslint-disable */
/**
 * sengikyo-document-search API
 * sengikyo-document-search API description
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AttributeItem
 */
export interface AttributeItem {
  /**
   *
   * @type {string}
   * @memberof AttributeItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AttributeItem
   */
  word: string;
}
/**
 *
 * @export
 * @interface AttributeModel
 */
export interface AttributeModel {
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  deleted_at: string;
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  word: string;
}
/**
 *
 * @export
 * @interface AuthLoginRequest
 */
export interface AuthLoginRequest {
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AuthLoginRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface DeleteSynonymRequest
 */
export interface DeleteSynonymRequest {
  /**
   *
   * @type {string}
   * @memberof DeleteSynonymRequest
   */
  word: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const EngineType = {
  Es: 'ES',
  Bert: 'BERT',
} as const;

export type EngineType = typeof EngineType[keyof typeof EngineType];

/**
 *
 * @export
 * @enum {string}
 */

export const OperationEnum = {
  GetObject: 'getObject',
  PutObject: 'putObject',
} as const;

export type OperationEnum = typeof OperationEnum[keyof typeof OperationEnum];

/**
 *
 * @export
 * @interface PdfGroup
 */
export interface PdfGroup {
  /**
   *
   * @type {Array<AttributeItem>}
   * @memberof PdfGroup
   */
  attributes: Array<AttributeItem>;
  /**
   *
   * @type {string}
   * @memberof PdfGroup
   */
  company_id: string;
  /**
   *
   * @type {Array<PdfInfo>}
   * @memberof PdfGroup
   */
  content: Array<PdfInfo>;
  /**
   *
   * @type {string}
   * @memberof PdfGroup
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof PdfGroup
   */
  file_path: string;
  /**
   *
   * @type {string}
   * @memberof PdfGroup
   */
  pdf_created_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfGroup
   */
  pdf_id: string;
}
/**
 *
 * @export
 * @interface PdfInfo
 */
export interface PdfInfo {
  /**
   *
   * @type {EngineType}
   * @memberof PdfInfo
   */
  engine: EngineType;
  /**
   *
   * @type {number}
   * @memberof PdfInfo
   */
  page_number: number;
  /**
   *
   * @type {number}
   * @memberof PdfInfo
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof PdfInfo
   */
  text: string;
}
/**
 *
 * @export
 * @interface PdfModel
 */
export interface PdfModel {
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  file_path: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  pdf_created_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  pdf_created_by: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  pdf_deleted_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  pdf_deleted_by: string;
  /**
   *
   * @type {object}
   * @memberof PdfModel
   */
  status: object;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  updated_by: string;
  /**
   *
   * @type {string}
   * @memberof PdfModel
   */
  uploaded_by: string;
}
/**
 *
 * @export
 * @interface PdfResponse
 */
export interface PdfResponse {
  /**
   *
   * @type {Array<AttributeItem>}
   * @memberof PdfResponse
   */
  attributes: Array<AttributeItem>;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  file_path: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  pdf_created_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  pdf_created_by: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  pdf_deleted_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  pdf_deleted_by: string;
  /**
   *
   * @type {PdfStatus}
   * @memberof PdfResponse
   */
  status: PdfStatus;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  updated_by: string;
  /**
   *
   * @type {string}
   * @memberof PdfResponse
   */
  uploaded_by: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PdfStatus = {
  Waiting: 'WAITING',
  Processing: 'PROCESSING',
  Done: 'DONE',
} as const;

export type PdfStatus = typeof PdfStatus[keyof typeof PdfStatus];

/**
 *
 * @export
 * @interface PostAttributeRequest
 */
export interface PostAttributeRequest {
  /**
   *
   * @type {string}
   * @memberof PostAttributeRequest
   */
  word: string;
}
/**
 *
 * @export
 * @interface PostNewSynonymRequest
 */
export interface PostNewSynonymRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof PostNewSynonymRequest
   */
  words: Array<string>;
}
/**
 *
 * @export
 * @interface PostPdfRequest
 */
export interface PostPdfRequest {
  /**
   *
   * @type {Array<AttributeItem>}
   * @memberof PostPdfRequest
   */
  attributes: Array<AttributeItem>;
  /**
   *
   * @type {string}
   * @memberof PostPdfRequest
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof PostPdfRequest
   */
  pdf_created_at: string;
  /**
   *
   * @type {string}
   * @memberof PostPdfRequest
   */
  pdf_created_by: string;
}
/**
 *
 * @export
 * @interface PostSearchLogRequest
 */
export interface PostSearchLogRequest {
  /**
   *
   * @type {string}
   * @memberof PostSearchLogRequest
   */
  query: string;
  /**
   *
   * @type {string}
   * @memberof PostSearchLogRequest
   */
  target: string;
}
/**
 *
 * @export
 * @interface PostUserRequest
 */
export interface PostUserRequest {
  /**
   *
   * @type {string}
   * @memberof PostUserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PostUserRequest
   */
  name: string;
}
/**
 *
 * @export
 * @interface PresignedUrlRequest
 */
export interface PresignedUrlRequest {
  /**
   *
   * @type {string}
   * @memberof PresignedUrlRequest
   */
  file_path: string;
  /**
   *
   * @type {OperationEnum}
   * @memberof PresignedUrlRequest
   */
  operation: OperationEnum;
}
/**
 *
 * @export
 * @interface PresignedUrlResponse
 */
export interface PresignedUrlResponse {
  /**
   *
   * @type {string}
   * @memberof PresignedUrlResponse
   */
  presigned_url: string;
}
/**
 *
 * @export
 * @interface SearchLogModel
 */
export interface SearchLogModel {
  /**
   *
   * @type {string}
   * @memberof SearchLogModel
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogModel
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogModel
   */
  created_by: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogModel
   */
  query: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogModel
   */
  target: string;
}
/**
 *
 * @export
 * @interface SearchLogResponse
 */
export interface SearchLogResponse {
  /**
   *
   * @type {string}
   * @memberof SearchLogResponse
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogResponse
   */
  created_by_name: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogResponse
   */
  query: string;
  /**
   *
   * @type {string}
   * @memberof SearchLogResponse
   */
  target: string;
}
/**
 *
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
  /**
   *
   * @type {number}
   * @memberof SearchResponse
   */
  hit_count: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SearchResponse
   */
  related_queries: Array<string>;
  /**
   *
   * @type {Array<PdfGroup>}
   * @memberof SearchResponse
   */
  result: Array<PdfGroup>;
}
/**
 *
 * @export
 * @interface SengikyoIdResponse
 */
export interface SengikyoIdResponse {
  /**
   *
   * @type {string}
   * @memberof SengikyoIdResponse
   */
  sengikyo_company_id: string;
}
/**
 *
 * @export
 * @interface SynonymResponse
 */
export interface SynonymResponse {
  /**
   *
   * @type {string}
   * @memberof SynonymResponse
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof SynonymResponse
   */
  group_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SynonymResponse
   */
  words: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TargetEnum = {
  Internal: 'internal',
  Sengikyo: 'sengikyo',
} as const;

export type TargetEnum = typeof TargetEnum[keyof typeof TargetEnum];

/**
 *
 * @export
 * @interface UpdateAttributeRequest
 */
export interface UpdateAttributeRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateAttributeRequest
   */
  word: string;
}
/**
 *
 * @export
 * @interface UpdatePdfRequest
 */
export interface UpdatePdfRequest {
  /**
   *
   * @type {Array<AttributeItem>}
   * @memberof UpdatePdfRequest
   */
  attributes: Array<AttributeItem>;
  /**
   *
   * @type {string}
   * @memberof UpdatePdfRequest
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePdfRequest
   */
  pdf_created_at: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePdfRequest
   */
  pdf_created_by: string;
}
/**
 *
 * @export
 * @interface UpdateSynonymRequest
 */
export interface UpdateSynonymRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSynonymRequest
   */
  current_word: string;
  /**
   *
   * @type {string}
   * @memberof UpdateSynonymRequest
   */
  prev_word: string;
}
/**
 *
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  password: string;
}
/**
 *
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  company_id: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  created_at: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  deleted_at: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  last_login_at: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  updated_at: string;
  /**
   *
   * @type {string}
   * @memberof UserResponse
   */
  user_id: string;
}

/**
 * AttributeApi - axios parameter creator
 * @export
 */
export const AttributeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerDeleteAttribute: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attributeControllerDeleteAttribute', 'id', id);
      const localVarPath = `/attribute/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerGetAttributeById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attributeControllerGetAttributeById', 'id', id);
      const localVarPath = `/attribute/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerGetAttributes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/attribute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PostAttributeRequest} postAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerPostAttribute: async (
      postAttributeRequest: PostAttributeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postAttributeRequest' is not null or undefined
      assertParamExists('attributeControllerPostAttribute', 'postAttributeRequest', postAttributeRequest);
      const localVarPath = `/attribute`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(postAttributeRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateAttributeRequest} updateAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerUpdateAttribute: async (
      id: string,
      updateAttributeRequest: UpdateAttributeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('attributeControllerUpdateAttribute', 'id', id);
      // verify required parameter 'updateAttributeRequest' is not null or undefined
      assertParamExists('attributeControllerUpdateAttribute', 'updateAttributeRequest', updateAttributeRequest);
      const localVarPath = `/attribute/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAttributeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AttributeApi - functional programming interface
 * @export
 */
export const AttributeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AttributeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attributeControllerDeleteAttribute(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.attributeControllerDeleteAttribute(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attributeControllerGetAttributeById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttributeModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.attributeControllerGetAttributeById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attributeControllerGetAttributes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttributeModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.attributeControllerGetAttributes(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PostAttributeRequest} postAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attributeControllerPostAttribute(
      postAttributeRequest: PostAttributeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttributeModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.attributeControllerPostAttribute(
        postAttributeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateAttributeRequest} updateAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async attributeControllerUpdateAttribute(
      id: string,
      updateAttributeRequest: UpdateAttributeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.attributeControllerUpdateAttribute(
        id,
        updateAttributeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AttributeApi - factory interface
 * @export
 */
export const AttributeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AttributeApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerDeleteAttribute(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.attributeControllerDeleteAttribute(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerGetAttributeById(id: string, options?: any): AxiosPromise<AttributeModel> {
      return localVarFp.attributeControllerGetAttributeById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerGetAttributes(options?: any): AxiosPromise<Array<AttributeModel>> {
      return localVarFp.attributeControllerGetAttributes(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PostAttributeRequest} postAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerPostAttribute(
      postAttributeRequest: PostAttributeRequest,
      options?: any
    ): AxiosPromise<AttributeModel> {
      return localVarFp
        .attributeControllerPostAttribute(postAttributeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateAttributeRequest} updateAttributeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    attributeControllerUpdateAttribute(
      id: string,
      updateAttributeRequest: UpdateAttributeRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .attributeControllerUpdateAttribute(id, updateAttributeRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AttributeApi - object-oriented interface
 * @export
 * @class AttributeApi
 * @extends {BaseAPI}
 */
export class AttributeApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttributeApi
   */
  public attributeControllerDeleteAttribute(id: string, options?: AxiosRequestConfig) {
    return AttributeApiFp(this.configuration)
      .attributeControllerDeleteAttribute(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttributeApi
   */
  public attributeControllerGetAttributeById(id: string, options?: AxiosRequestConfig) {
    return AttributeApiFp(this.configuration)
      .attributeControllerGetAttributeById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttributeApi
   */
  public attributeControllerGetAttributes(options?: AxiosRequestConfig) {
    return AttributeApiFp(this.configuration)
      .attributeControllerGetAttributes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PostAttributeRequest} postAttributeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttributeApi
   */
  public attributeControllerPostAttribute(postAttributeRequest: PostAttributeRequest, options?: AxiosRequestConfig) {
    return AttributeApiFp(this.configuration)
      .attributeControllerPostAttribute(postAttributeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateAttributeRequest} updateAttributeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AttributeApi
   */
  public attributeControllerUpdateAttribute(
    id: string,
    updateAttributeRequest: UpdateAttributeRequest,
    options?: AxiosRequestConfig
  ) {
    return AttributeApiFp(this.configuration)
      .attributeControllerUpdateAttribute(id, updateAttributeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogin: async (
      authLoginRequest: AuthLoginRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authLoginRequest' is not null or undefined
      assertParamExists('authControllerLogin', 'authLoginRequest', authLoginRequest);
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(authLoginRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerLogin(
      authLoginRequest: AuthLoginRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(authLoginRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerLogout(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {AuthLoginRequest} authLoginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogin(authLoginRequest: AuthLoginRequest, options?: any): AxiosPromise<void> {
      return localVarFp.authControllerLogin(authLoginRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerLogout(options?: any): AxiosPromise<void> {
      return localVarFp.authControllerLogout(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {AuthLoginRequest} authLoginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerLogin(authLoginRequest: AuthLoginRequest, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerLogin(authLoginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerLogout(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerLogout(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetSengikyoId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/company/sengikyo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companyControllerGetSengikyoId(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SengikyoIdResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerGetSengikyoId(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CompanyApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companyControllerGetSengikyoId(options?: any): AxiosPromise<SengikyoIdResponse> {
      return localVarFp.companyControllerGetSengikyoId(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companyControllerGetSengikyoId(options?: AxiosRequestConfig) {
    return CompanyApiFp(this.configuration)
      .companyControllerGetSengikyoId(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appControllerGetHello(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appControllerGetHello(options?: any): AxiosPromise<string> {
      return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public appControllerGetHello(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .appControllerGetHello(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PdfApi - axios parameter creator
 * @export
 */
export const PdfApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerDeletePdf: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('pdfControllerDeletePdf', 'id', id);
      const localVarPath = `/pdf/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerGetPdfById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('pdfControllerGetPdfById', 'id', id);
      const localVarPath = `/pdf/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerGetPdfs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/pdf`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PresignedUrlRequest} presignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerGetPresignedUrl: async (
      presignedUrlRequest: PresignedUrlRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'presignedUrlRequest' is not null or undefined
      assertParamExists('pdfControllerGetPresignedUrl', 'presignedUrlRequest', presignedUrlRequest);
      const localVarPath = `/pdf/presigned_url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(presignedUrlRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PostPdfRequest} postPdfRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerPostPdf: async (
      postPdfRequest: PostPdfRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postPdfRequest' is not null or undefined
      assertParamExists('pdfControllerPostPdf', 'postPdfRequest', postPdfRequest);
      const localVarPath = `/pdf`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(postPdfRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePdfRequest} updatePdfRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerUpdatePdf: async (
      id: string,
      updatePdfRequest: UpdatePdfRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('pdfControllerUpdatePdf', 'id', id);
      // verify required parameter 'updatePdfRequest' is not null or undefined
      assertParamExists('pdfControllerUpdatePdf', 'updatePdfRequest', updatePdfRequest);
      const localVarPath = `/pdf/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updatePdfRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PdfApi - functional programming interface
 * @export
 */
export const PdfApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PdfApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pdfControllerDeletePdf(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerDeletePdf(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pdfControllerGetPdfById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerGetPdfById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pdfControllerGetPdfs(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PdfResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerGetPdfs(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PresignedUrlRequest} presignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pdfControllerGetPresignedUrl(
      presignedUrlRequest: PresignedUrlRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerGetPresignedUrl(
        presignedUrlRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PostPdfRequest} postPdfRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pdfControllerPostPdf(
      postPdfRequest: PostPdfRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerPostPdf(postPdfRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePdfRequest} updatePdfRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pdfControllerUpdatePdf(
      id: string,
      updatePdfRequest: UpdatePdfRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pdfControllerUpdatePdf(id, updatePdfRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PdfApi - factory interface
 * @export
 */
export const PdfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PdfApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerDeletePdf(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.pdfControllerDeletePdf(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerGetPdfById(id: string, options?: any): AxiosPromise<PdfResponse> {
      return localVarFp.pdfControllerGetPdfById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerGetPdfs(options?: any): AxiosPromise<Array<PdfResponse>> {
      return localVarFp.pdfControllerGetPdfs(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PresignedUrlRequest} presignedUrlRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerGetPresignedUrl(
      presignedUrlRequest: PresignedUrlRequest,
      options?: any
    ): AxiosPromise<PresignedUrlResponse> {
      return localVarFp
        .pdfControllerGetPresignedUrl(presignedUrlRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PostPdfRequest} postPdfRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerPostPdf(postPdfRequest: PostPdfRequest, options?: any): AxiosPromise<PdfModel> {
      return localVarFp.pdfControllerPostPdf(postPdfRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdatePdfRequest} updatePdfRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pdfControllerUpdatePdf(id: string, updatePdfRequest: UpdatePdfRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .pdfControllerUpdatePdf(id, updatePdfRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PdfApi - object-oriented interface
 * @export
 * @class PdfApi
 * @extends {BaseAPI}
 */
export class PdfApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfApi
   */
  public pdfControllerDeletePdf(id: string, options?: AxiosRequestConfig) {
    return PdfApiFp(this.configuration)
      .pdfControllerDeletePdf(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfApi
   */
  public pdfControllerGetPdfById(id: string, options?: AxiosRequestConfig) {
    return PdfApiFp(this.configuration)
      .pdfControllerGetPdfById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfApi
   */
  public pdfControllerGetPdfs(options?: AxiosRequestConfig) {
    return PdfApiFp(this.configuration)
      .pdfControllerGetPdfs(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PresignedUrlRequest} presignedUrlRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfApi
   */
  public pdfControllerGetPresignedUrl(presignedUrlRequest: PresignedUrlRequest, options?: AxiosRequestConfig) {
    return PdfApiFp(this.configuration)
      .pdfControllerGetPresignedUrl(presignedUrlRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PostPdfRequest} postPdfRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfApi
   */
  public pdfControllerPostPdf(postPdfRequest: PostPdfRequest, options?: AxiosRequestConfig) {
    return PdfApiFp(this.configuration)
      .pdfControllerPostPdf(postPdfRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdatePdfRequest} updatePdfRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfApi
   */
  public pdfControllerUpdatePdf(id: string, updatePdfRequest: UpdatePdfRequest, options?: AxiosRequestConfig) {
    return PdfApiFp(this.configuration)
      .pdfControllerUpdatePdf(id, updatePdfRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {TargetEnum} target
     * @param {string} q
     * @param {boolean} [isExactMatch]
     * @param {Array<string>} [attr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchControllerSearch: async (
      target: TargetEnum,
      q: string,
      isExactMatch?: boolean,
      attr?: Array<string>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'target' is not null or undefined
      assertParamExists('searchControllerSearch', 'target', target);
      // verify required parameter 'q' is not null or undefined
      assertParamExists('searchControllerSearch', 'q', q);
      const localVarPath = `/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (target !== undefined) {
        localVarQueryParameter['target'] = target;
      }

      if (isExactMatch !== undefined) {
        localVarQueryParameter['is_exact_match'] = isExactMatch;
      }

      if (attr) {
        localVarQueryParameter['attr'] = attr;
      }

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {TargetEnum} target
     * @param {string} q
     * @param {boolean} [isExactMatch]
     * @param {Array<string>} [attr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchControllerSearch(
      target: TargetEnum,
      q: string,
      isExactMatch?: boolean,
      attr?: Array<string>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchControllerSearch(
        target,
        q,
        isExactMatch,
        attr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SearchApiFp(configuration);
  return {
    /**
     *
     * @param {TargetEnum} target
     * @param {string} q
     * @param {boolean} [isExactMatch]
     * @param {Array<string>} [attr]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchControllerSearch(
      target: TargetEnum,
      q: string,
      isExactMatch?: boolean,
      attr?: Array<string>,
      options?: any
    ): AxiosPromise<SearchResponse> {
      return localVarFp
        .searchControllerSearch(target, q, isExactMatch, attr, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
  /**
   *
   * @param {TargetEnum} target
   * @param {string} q
   * @param {boolean} [isExactMatch]
   * @param {Array<string>} [attr]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchApi
   */
  public searchControllerSearch(
    target: TargetEnum,
    q: string,
    isExactMatch?: boolean,
    attr?: Array<string>,
    options?: AxiosRequestConfig
  ) {
    return SearchApiFp(this.configuration)
      .searchControllerSearch(target, q, isExactMatch, attr, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SearchLogApi - axios parameter creator
 * @export
 */
export const SearchLogApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchLogControllerGetSearchLogs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PostSearchLogRequest} postSearchLogRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchLogControllerPostSearchLog: async (
      postSearchLogRequest: PostSearchLogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postSearchLogRequest' is not null or undefined
      assertParamExists('searchLogControllerPostSearchLog', 'postSearchLogRequest', postSearchLogRequest);
      const localVarPath = `/log`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(postSearchLogRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SearchLogApi - functional programming interface
 * @export
 */
export const SearchLogApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SearchLogApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchLogControllerGetSearchLogs(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchLogResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchLogControllerGetSearchLogs(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PostSearchLogRequest} postSearchLogRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchLogControllerPostSearchLog(
      postSearchLogRequest: PostSearchLogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchLogModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchLogControllerPostSearchLog(
        postSearchLogRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SearchLogApi - factory interface
 * @export
 */
export const SearchLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SearchLogApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchLogControllerGetSearchLogs(options?: any): AxiosPromise<Array<SearchLogResponse>> {
      return localVarFp.searchLogControllerGetSearchLogs(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PostSearchLogRequest} postSearchLogRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchLogControllerPostSearchLog(
      postSearchLogRequest: PostSearchLogRequest,
      options?: any
    ): AxiosPromise<SearchLogModel> {
      return localVarFp
        .searchLogControllerPostSearchLog(postSearchLogRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SearchLogApi - object-oriented interface
 * @export
 * @class SearchLogApi
 * @extends {BaseAPI}
 */
export class SearchLogApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchLogApi
   */
  public searchLogControllerGetSearchLogs(options?: AxiosRequestConfig) {
    return SearchLogApiFp(this.configuration)
      .searchLogControllerGetSearchLogs(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PostSearchLogRequest} postSearchLogRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SearchLogApi
   */
  public searchLogControllerPostSearchLog(postSearchLogRequest: PostSearchLogRequest, options?: AxiosRequestConfig) {
    return SearchLogApiFp(this.configuration)
      .searchLogControllerPostSearchLog(postSearchLogRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SynonymApi - axios parameter creator
 * @export
 */
export const SynonymApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} groupId
     * @param {DeleteSynonymRequest} deleteSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerDeleteSynonym: async (
      groupId: string,
      deleteSynonymRequest: DeleteSynonymRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('synonymControllerDeleteSynonym', 'groupId', groupId);
      // verify required parameter 'deleteSynonymRequest' is not null or undefined
      assertParamExists('synonymControllerDeleteSynonym', 'deleteSynonymRequest', deleteSynonymRequest);
      const localVarPath = `/synonym/{groupId}`.replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(deleteSynonymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerGetSynonymByGroupId: async (
      groupId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('synonymControllerGetSynonymByGroupId', 'groupId', groupId);
      const localVarPath = `/synonym/{groupId}`.replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerGetSynonyms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/synonym`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PostNewSynonymRequest} postNewSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerPostNewSynonym: async (
      postNewSynonymRequest: PostNewSynonymRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postNewSynonymRequest' is not null or undefined
      assertParamExists('synonymControllerPostNewSynonym', 'postNewSynonymRequest', postNewSynonymRequest);
      const localVarPath = `/synonym`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(postNewSynonymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} groupId
     * @param {UpdateSynonymRequest} updateSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerUpdateSynonym: async (
      groupId: string,
      updateSynonymRequest: UpdateSynonymRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('synonymControllerUpdateSynonym', 'groupId', groupId);
      // verify required parameter 'updateSynonymRequest' is not null or undefined
      assertParamExists('synonymControllerUpdateSynonym', 'updateSynonymRequest', updateSynonymRequest);
      const localVarPath = `/synonym/{groupId}`.replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateSynonymRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SynonymApi - functional programming interface
 * @export
 */
export const SynonymApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SynonymApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} groupId
     * @param {DeleteSynonymRequest} deleteSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async synonymControllerDeleteSynonym(
      groupId: string,
      deleteSynonymRequest: DeleteSynonymRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.synonymControllerDeleteSynonym(
        groupId,
        deleteSynonymRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async synonymControllerGetSynonymByGroupId(
      groupId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SynonymResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.synonymControllerGetSynonymByGroupId(groupId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async synonymControllerGetSynonyms(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SynonymResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.synonymControllerGetSynonyms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PostNewSynonymRequest} postNewSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async synonymControllerPostNewSynonym(
      postNewSynonymRequest: PostNewSynonymRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.synonymControllerPostNewSynonym(
        postNewSynonymRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} groupId
     * @param {UpdateSynonymRequest} updateSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async synonymControllerUpdateSynonym(
      groupId: string,
      updateSynonymRequest: UpdateSynonymRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.synonymControllerUpdateSynonym(
        groupId,
        updateSynonymRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SynonymApi - factory interface
 * @export
 */
export const SynonymApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SynonymApiFp(configuration);
  return {
    /**
     *
     * @param {string} groupId
     * @param {DeleteSynonymRequest} deleteSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerDeleteSynonym(
      groupId: string,
      deleteSynonymRequest: DeleteSynonymRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .synonymControllerDeleteSynonym(groupId, deleteSynonymRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerGetSynonymByGroupId(groupId: string, options?: any): AxiosPromise<SynonymResponse> {
      return localVarFp
        .synonymControllerGetSynonymByGroupId(groupId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerGetSynonyms(options?: any): AxiosPromise<Array<SynonymResponse>> {
      return localVarFp.synonymControllerGetSynonyms(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PostNewSynonymRequest} postNewSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerPostNewSynonym(postNewSynonymRequest: PostNewSynonymRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .synonymControllerPostNewSynonym(postNewSynonymRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} groupId
     * @param {UpdateSynonymRequest} updateSynonymRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    synonymControllerUpdateSynonym(
      groupId: string,
      updateSynonymRequest: UpdateSynonymRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .synonymControllerUpdateSynonym(groupId, updateSynonymRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SynonymApi - object-oriented interface
 * @export
 * @class SynonymApi
 * @extends {BaseAPI}
 */
export class SynonymApi extends BaseAPI {
  /**
   *
   * @param {string} groupId
   * @param {DeleteSynonymRequest} deleteSynonymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymApi
   */
  public synonymControllerDeleteSynonym(
    groupId: string,
    deleteSynonymRequest: DeleteSynonymRequest,
    options?: AxiosRequestConfig
  ) {
    return SynonymApiFp(this.configuration)
      .synonymControllerDeleteSynonym(groupId, deleteSynonymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymApi
   */
  public synonymControllerGetSynonymByGroupId(groupId: string, options?: AxiosRequestConfig) {
    return SynonymApiFp(this.configuration)
      .synonymControllerGetSynonymByGroupId(groupId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymApi
   */
  public synonymControllerGetSynonyms(options?: AxiosRequestConfig) {
    return SynonymApiFp(this.configuration)
      .synonymControllerGetSynonyms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PostNewSynonymRequest} postNewSynonymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymApi
   */
  public synonymControllerPostNewSynonym(postNewSynonymRequest: PostNewSynonymRequest, options?: AxiosRequestConfig) {
    return SynonymApiFp(this.configuration)
      .synonymControllerPostNewSynonym(postNewSynonymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} groupId
   * @param {UpdateSynonymRequest} updateSynonymRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SynonymApi
   */
  public synonymControllerUpdateSynonym(
    groupId: string,
    updateSynonymRequest: UpdateSynonymRequest,
    options?: AxiosRequestConfig
  ) {
    return SynonymApiFp(this.configuration)
      .synonymControllerUpdateSynonym(groupId, updateSynonymRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userControllerDeleteUser', 'id', id);
      const localVarPath = `/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userControllerGetUserById', 'id', id);
      const localVarPath = `/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PostUserRequest} postUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerPostUser: async (
      postUserRequest: PostUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postUserRequest' is not null or undefined
      assertParamExists('userControllerPostUser', 'postUserRequest', postUserRequest);
      const localVarPath = `/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(postUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdateUser: async (
      id: string,
      updateUserRequest: UpdateUserRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userControllerUpdateUser', 'id', id);
      // verify required parameter 'updateUserRequest' is not null or undefined
      assertParamExists('userControllerUpdateUser', 'updateUserRequest', updateUserRequest);
      const localVarPath = `/user/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerDeleteUser(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteUser(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetMe(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetMe(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetUserById(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUserById(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerGetUsers(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetUsers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {PostUserRequest} postUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerPostUser(
      postUserRequest: PostUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerPostUser(postUserRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userControllerUpdateUser(
      id: string,
      updateUserRequest: UpdateUserRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdateUser(
        id,
        updateUserRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration);
  return {
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerDeleteUser(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.userControllerDeleteUser(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetMe(options?: any): AxiosPromise<UserResponse> {
      return localVarFp.userControllerGetMe(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUserById(id: string, options?: any): AxiosPromise<UserResponse> {
      return localVarFp.userControllerGetUserById(id, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerGetUsers(options?: any): AxiosPromise<Array<UserResponse>> {
      return localVarFp.userControllerGetUsers(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PostUserRequest} postUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerPostUser(postUserRequest: PostUserRequest, options?: any): AxiosPromise<UserResponse> {
      return localVarFp.userControllerPostUser(postUserRequest, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userControllerUpdateUser(id: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .userControllerUpdateUser(id, updateUserRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerDeleteUser(id: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerDeleteUser(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerGetMe(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerGetMe(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerGetUserById(id: string, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerGetUserById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerGetUsers(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerGetUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {PostUserRequest} postUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerPostUser(postUserRequest: PostUserRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerPostUser(postUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {UpdateUserRequest} updateUserRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public userControllerUpdateUser(id: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .userControllerUpdateUser(id, updateUserRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
