import { Moment } from 'moment';

// ここで定義されている型はbackendとの繋ぎこみをする際細かいことを決める
export const SEARCH_TARGET_COMPANY = 'internal';
export const SEARCH_TARGET_ASSOCIATION = 'sengikyo';
export type SearchTarget = 'internal' | 'sengikyo';

export interface Attribute {
  word: string;
  id: string;
}

export interface SearchRequest {
  searchKeyword: string;
  searchTarget: SearchTarget;
  searchAttributes: string[];
  searchDateRange: [Moment | null, Moment | null] | null;
  searchIsExactMatch: boolean;
}

export type SearchFormInitialValues = SearchRequest & { sort: string };

export type SortType = 'SCORE' | 'NAME_DESC' | 'NAME_ASC' | 'DATE_DESC' | 'DATE_ASC';
