import { Button, Card, Form, Input, Layout, Row, Col, message } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userApi } from '../api/apiClient';
import '../styles/common.css';
import '../styles/button.css';
import { PostUserRequest } from '../generated-api';
import validateMessages from '../utils/validateMessages';

const UserAddComponent: React.FC = () => {
  const rowGutterParam = 24;
  const labelSpanParam = 8;
  const formSpanParam = 14;

  const { Content } = Layout;
  const navigate = useNavigate();
  const toUserHome = () => {
    navigate('/user');
  };

  // 新規ユーザー登録処理
  const onFinish = async (fieldsValue: PostUserRequest) => {
    try {
      const userAddRequest = { name: fieldsValue.name, email: fieldsValue.email };
      await userApi.userControllerPostUser(userAddRequest);
      await message.info(`${fieldsValue.email} 宛てにメールを送信しました。ご確認ください。`);
    } catch (e) {
      await message.error('新規ユーザー追加に失敗しました。');
    }
    navigate('/user');
  };

  return (
    <Content className="contentCenter">
      <Row gutter={rowGutterParam}>
        <Form name="basic" onFinish={onFinish} autoComplete="off" colon={false} validateMessages={validateMessages}>
          <Card className="userCardStyle">
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="colStyle">
                <Content className="formLabel">ユーザ名</Content>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item name="name" rules={[{ required: true }]}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={rowGutterParam} className="rowStyle">
              <Col span={labelSpanParam} className="colStyle">
                <Content className="formLabel">Email</Content>
              </Col>
              <Col span={formSpanParam}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'emailの形式ではありません',
                    },
                  ]}>
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Row gutter={rowGutterParam} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="userButton">
                追加
              </Button>
            </Form.Item>
            <Form.Item>
              <Button htmlType="button" className="userButton" onClick={toUserHome}>
                戻る
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Row>
    </Content>
  );
};

export default UserAddComponent;
