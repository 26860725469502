import { Layout } from 'antd';
import React from 'react';
import LoginComponent from '../components/Login';

const Login: React.FC = () => (
  <Layout style={{ height: '100vh', backgroundColor: '#BCE0FD' }}>
    <LoginComponent />
  </Layout>
);

export default Login;
