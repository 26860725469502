import { Layout } from 'antd';
import React from 'react';
import HeaderComponent from '../components/common/HeaderComponent';
import UserAddComponent from '../components/UserAdd';

const UserAdd: React.FC = () => (
  <Layout style={{ backgroundColor: 'white' }}>
    <HeaderComponent />
    <UserAddComponent />
  </Layout>
);

export default UserAdd;
