import { Button, Layout, Table, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderComponent from '../components/common/HeaderComponent';
import { searchLogApi } from '../api/apiClient';
import { SearchLogResponse } from '../generated-api';
import { ISOStringToHumanReadable } from '../utils/dateUtils';

const SearchLog: React.FC = () => {
  const navigate = useNavigate();
  const backToSearch = () => {
    navigate('/search');
  };
  const [searchLogList, setSearchLogList] = useState<SearchLogResponse[]>([]);

  useEffect(() => {
    const getSearchLog = async () => {
      const searchLogRes = await searchLogApi.searchLogControllerGetSearchLogs();
      const toBeSet = searchLogRes.data.reverse().map((item) => ({
        ...item,
        created_at: ISOStringToHumanReadable(item.created_at),
      }));
      setSearchLogList(toBeSet);
    };
    getSearchLog().catch(console.error);
  }, []);

  const border = '1px #bfbfbf solid';
  const tableStyle = {
    margin: '3em auto',
    width: '60%',
    border,
  };
  const buttonStyle = {
    width: '120px',
  };

  const columns = [
    { title: '検索日時', dataIndex: 'created_at' },
    { title: 'ユーザ名', dataIndex: 'created_by_name' },
    {
      title: '検索キーワード',
      dataIndex: 'query',
      render: (text: string) => (
        <Typography.Link onClick={() => navigate(`/search/?q=${text}`)}>{text}</Typography.Link>
      ),
    },
    {
      title: '検索対象',
      dataIndex: 'target',
      render: (text: string, record: SearchLogResponse): JSX.Element => (
        <span>
          {record.target === 'internal' && <Tag>社内</Tag>}
          {record.target === 'sengikyo' && <Tag>船技協</Tag>}
        </span>
      ),
    },
  ];

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <Table
        rowKey={(record) => record.id}
        dataSource={searchLogList}
        columns={columns}
        style={tableStyle}
        pagination={{
          position: ['bottomCenter'],
          showTotal: (total, range): string => `${range[0]}-${range[1]} of ${total} logs`,
        }}
        onRow={(record: SearchLogResponse) => ({
          onClick: () => navigate(`/search/?q=${record.query}&target=${record.target}`),
        })}
      />
      <div style={{ textAlign: 'center' }}>
        <Button style={buttonStyle} onClick={backToSearch}>
          戻る
        </Button>
      </div>
    </Layout>
  );
};

export default SearchLog;
