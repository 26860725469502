import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import HeaderComponent from '../components/common/HeaderComponent';
import UserListComponent from '../components/UserList';
import { userApi } from '../api/apiClient';
import { UserResponse } from '../generated-api';
import { ISOStringToHumanReadable } from '../utils/dateUtils';

const UserList: React.FC = () => {
  const [userList, setUserList] = useState<UserResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // ユーザー一覧を取得する処理
  useEffect(() => {
    const fetchUserList: () => Promise<void> = async () => {
      setIsLoading(true);
      const users = await userApi.userControllerGetUsers();
      const userArray = [...users.data].map((user) => {
        if (user.last_login_at) {
          return { ...user, last_login_at: ISOStringToHumanReadable(user.last_login_at) };
        }
        return { ...user };
      });
      setUserList(userArray);
    };
    fetchUserList()
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <HeaderComponent />
      <UserListComponent usersInfo={userList} isLoading={isLoading} />
    </Layout>
  );
};

export default UserList;
