import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { SearchResponse } from '../generated-api';
import { SearchRequest } from '../models/search';

export const searchResultBeforeFilterState = atom<SearchResponse | null>({
  key: 'searchResultBeforeFilter',
  default: undefined,
});

export const useSearchResultBeforeFilter = () => {
  const savedSearchResultBeforeFilter = useRecoilValue(searchResultBeforeFilterState);
  const setSavedSearchResultBeforeFilter = useSetRecoilState(searchResultBeforeFilterState);
  return { savedSearchResultBeforeFilter, setSavedSearchResultBeforeFilter };
};

export const searchResultState = atom<SearchResponse | null>({
  key: 'searchResult',
  default: undefined,
});

export const useSearchResult = () => {
  const savedSearchResult = useRecoilValue(searchResultState);
  const setSavedSearchResult = useSetRecoilState(searchResultState);
  return { savedSearchResult, setSavedSearchResult };
};

export const searchRequestState = atom<SearchRequest | null>({
  key: 'searchRequest',
  default: undefined,
});

export const useSearchRequest = () => {
  const savedSearchRequest = useRecoilValue(searchRequestState);
  const setSavedSearchRequest = useSetRecoilState(searchRequestState);
  return { savedSearchRequest, setSavedSearchRequest };
};
